import React, {
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TemplateCard from "../components/TemplateCard";
import BackendFactory from "../API/BackendFactory";
import { UserInfoObject } from "../API/lib/JsonUtils";
import { QRCodeSVG } from "qrcode.react";
import { logout_sessionexpired } from "../logout";
import GlobalContext from "../GlobalContext";
import Debouncer from "./DebouncerUtility";
import ErrorCard from "./ErrorCard";

const defaultProps = {
  visible: true,
  maxSize: 256,
};

interface Props extends PropsWithChildren {
  visible?: boolean;
  maxSize?: number;
}

const debouncer = new Debouncer();
const PersonalQR: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentJson, setCurrentJson] = useState("");
  const [QRCodeSize, setQRCodeSize] = useState(props.maxSize || 256);
  const [errorCode, setErrorCode] = useState("");

  const globalContext = useContext(GlobalContext);

  useLayoutEffect(() => {
    function updateSize() {
      let val =
        Math.floor(
          Math.min(window.innerWidth - 100, window.innerHeight - 150) / 128
        ) * 128;
      val = Math.max(Math.min(val, props.maxSize || 256), 64);
      if (val != QRCodeSize) setQRCodeSize(val);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  });

  const BE = BackendFactory.getBackend();

  function renderQRCode() {
    if (loading) {
      return (
        <span style={{ display: props.visible ? "inline" : "none" }}>
          <TemplateCard />
        </span>
      );
    } else if (errorCode != ""){
      return (<ErrorCard errorCode={errorCode}/>)
    } else {
      const uo = new UserInfoObject(currentJson);

      return (
        <center>
          <div
            style={{
              display: props.visible ? "inline-block" : "none",
              background: "white",
              padding: "12px",
              borderRadius: "10px",
            }}
          >
            <QRCodeSVG size={QRCodeSize} value={uo.getUserInfo().qr} />
          </div>
        </center>
      );
    }
  }

  debouncer.set_debouncer(() => {
    BE.getUserInfo()
      .then((result) => {
        setCurrentJson(result);
        setLoading(false);
      })
      .catch((reason) => {
        if (reason == "Expired Token") {
          logout_sessionexpired(globalContext, t);
        } else {
          if(typeof reason == "string")
            setErrorCode(reason)
          else
            setErrorCode("UnexpectedError");

          setLoading(false);
        }
      });
  });

  if (loading) {
    debouncer.call();
  }

  return <center>{renderQRCode()}</center>;
};

PersonalQR.defaultProps = defaultProps;

export default PersonalQR;
