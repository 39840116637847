import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationFi from "./internalization/fi/translation.json";
//import translationSv from "./internalization/sv/translation.json";
//import translationJa from "./internalization/ja/translation.json";
import translationEn from "./internalization/en/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEn,
  },
  fi: {
    translation: translationFi,
  },
};
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;
