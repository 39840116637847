import { debounce } from "debounce";

class Debouncer {
  lambdafun = () => {
    /*This function will be overridden*/
  };
  call = () => {
    /*This function will be overridden*/
  };

  constructor() {
    this.call = debounce(this._debounce_func, 100);
  }
  _debounce_func() {
    this.lambdafun();
  }

  set_debouncer(fun: () => void): void {
    this.lambdafun = fun;
  }
}

export default Debouncer;
