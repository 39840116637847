import { useTranslation } from "react-i18next";
import React from "react";
import { MealItem } from "../API/lib/DataTypes";
import i18next from "../i18n";
import getFlavoriaLang from "../utils/flavoriaLang";

interface Props {
  mealItem: MealItem;
}

const FoodDataPage: React.FC<Props> = (props: Props) => {
  const mealItem = props.mealItem;

  const { t } = useTranslation();
  const chosenFood = mealItem.items.filter((item) => item.type === "SIDE");
  const extras = mealItem.items.filter(
    (item) =>
      item.type === "extra" &&
      (item.weight > 0 || (item.selectedQuantity && item.selectedQuantity > 0))
  );

  //NOTE: Finnish language always has something; it will be used as a fallback if there is nothing in currently selected language
  const languageKey: keyof MealItem["items"][number]["name"] = getFlavoriaLang(i18next.language);

  const totalWeight =
    Math.round(
      10 * mealItem.items.reduce((acc, item) => (acc += item.weight ?? 0), 0)
    ) / 10.0;

  const totalEnergy =
    Math.round(
      10 *
        mealItem.items.reduce(
          (acc, item) => (acc += item.nutrients.ENER ?? 0),
          0
        )
    ) / 10.0;

  return (
    <>
      <table
        style={{
          width: "100%",
          margin: ".5em 0",
          fontSize: ".875em",
          tableLayout: "auto",
        }}
      >
        <tbody>
          <tr
            style={{
              fontSize: "1rem",
            }}
          >
            <th
              style={{
                textAlign: "left",
                textTransform: "uppercase",
                borderBottom: "1px solid var(--ion-color-light-shade)",
                paddingBottom: ".25em",
                fontWeight: "bold",
              }}
            >
              {t("Chosen food")}
            </th>
            <th
              style={{
                textAlign: "left",
                paddingBottom: ".25em",
                borderBottom: "1px solid var(--ion-color-light-shade)",
              }}
            >
              g
            </th>
            <th
              style={{
                textAlign: "left",
                paddingBottom: ".25em",
                borderBottom: "1px solid var(--ion-color-light-shade)",
              }}
            >
              kcal
            </th>
          </tr>
          {chosenFood.length > 0 ? (
            chosenFood.map((food) => (
              <tr key={food.id}>
                <td style={{ padding: ".25em 0" }}>
                  {food.name[languageKey] != undefined &&
                  food.name[languageKey] != ""
                    ? food.name[languageKey]
                    : food.name["fi"]}
                </td>
                <td>{food.weight}</td>
                <td>{food.nutrients.ENER}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{t("No data")}.</td>
            </tr>
          )}
        </tbody>

        {extras.length > 0 && (
          <tbody>
            <tr
              style={{
                fontSize: "1rem",
              }}
            >
              <th
                style={{
                  textAlign: "left",
                  textTransform: "uppercase",
                  borderBottom: "1px solid var(--ion-color-light-shade)",
                  padding: "1em 0 .25em 0",
                  fontWeight: "bold",
                }}
              >
                {t("Extras")}
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "1em 0 .25em 0",
                  borderBottom: "1px solid var(--ion-color-light-shade)",
                }}
              >
                g
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "1em 0 .25em 0",
                  borderBottom: "1px solid var(--ion-color-light-shade)",
                }}
              >
                kcal
              </th>
            </tr>
            {extras.map((extra) => (
              <tr key={extra.id}>
                <td style={{ padding: ".25em 0" }}>
                  {extra.name[languageKey] != undefined &&
                  extra.name[languageKey] != ""
                    ? extra.name[languageKey]
                    : extra.name["fi"]}
                </td>
                <td>{extra.weight}</td>
                <td>{extra.nutrients.ENER}</td>
              </tr>
            ))}
          </tbody>
        )}
        <tfoot>
          <tr style={{ fontSize: "1rem" }}>
            <th
              style={{
                textAlign: "left",
                textTransform: "uppercase",
                paddingTop: ".5em",
                borderTop: "1px solid var(--ion-color-light-shade)",
              }}
            >
              {t("Total")}
            </th>
            <th
              style={{
                textAlign: "left",
                paddingTop: ".5em",
                borderTop: "1px solid var(--ion-color-light-shade)",
              }}
            >
              {totalWeight} g
            </th>
            <th
              style={{
                textAlign: "left",
                paddingTop: ".5em",
                borderTop: "1px solid var(--ion-color-light-shade)",
              }}
            >
              {totalEnergy} kcal
            </th>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default FoodDataPage;
