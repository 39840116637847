import AbstractBackend from "./AbstractBackend";
import DebugBackend from "./DebugBackend";
import MyFlavoriaBackend from "./MyFlavoriaBackend";
import { debug } from "./Debug.config";

class BackendFactory {
  static backend: AbstractBackend | null = null;
  static getBackend(): AbstractBackend {
    if (BackendFactory.backend == null)
      BackendFactory.backend = debug
        ? new DebugBackend()
        : new MyFlavoriaBackend();
    return BackendFactory.backend;
  }
}

export default BackendFactory;
