import React, { useContext, useState } from "react";
import TemplateCard from "./TemplateCard";
import { useTranslation } from "react-i18next";
import GlobalContext from "../GlobalContext";
import Debouncer from "./DebouncerUtility";
import BackendFactory from "../API/BackendFactory";
import { logout_sessionexpired } from "../logout";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle } from "@ionic/react";
import { NewsListObject } from "../API/lib/JsonUtils";
import ErrorCard from "./ErrorCard";
import getFlavoriaLang from "../utils/flavoriaLang";

interface Props {
    language: string;
}

const deboucer = new Debouncer();
let jsonlang = "";

const NewsCards: React.FC<Props> = (props : Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [errorCode, setErrorCode] = useState("");
    const [newsjson, setnewsjson] = useState("");
    const globalContext = useContext(GlobalContext);

    deboucer.set_debouncer(() => {
        jsonlang = getFlavoriaLang(props.language);
        BackendFactory.getBackend()
          .getNewsFeed(jsonlang)
          .then((result) => {
            setnewsjson(result);
            setLoading(false);
          })
          .catch((reason) => {
            if (reason == "Expired Token") {
              logout_sessionexpired(globalContext, t);
            } else {
              if(typeof reason == "string")
                setErrorCode(reason)
              else
                setErrorCode("UnexpectedError");
    
              setLoading(false);
            }
          });
      });

      if(!loading && newsjson != "" && getFlavoriaLang(props.language) != jsonlang) {
        setLoading(true);
      }
    
      if (loading) {
        deboucer.call();
      }

    if (loading) {
      return (
        <div>
          <TemplateCard />
          <TemplateCard />
          <TemplateCard />
        </div>
      );
    } else if (errorCode != ""){
      return (<ErrorCard errorCode={errorCode}/>)
    } else {
      if (newsjson === "") {
        return (
          <IonCard>
            <IonCardHeader>
              <IonTitle>{t("No News to report currently.")}</IonTitle>
            </IonCardHeader>
          </IonCard>
        );
      } else {
        const news = new NewsListObject(newsjson);
        const list: Array<React.ReactElement> = news
          .getNewsItems()
          .map((article): React.ReactElement => {
            const date = new Date(article.date);
            return (
              <IonCard key={article.key}>
                <IonCardHeader>
                  <IonCardTitle>{article.title}</IonCardTitle>
                  <IonCardSubtitle>{new Date(date).toLocaleDateString(props.language, { weekday: "short", year: "numeric", month: "numeric", day: "numeric" })}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <div
                    className="news-article"
                    dangerouslySetInnerHTML={{ __html: article.text }}
                  />
                  <IonButton
                    target="_blank"
                    href={article.link}
                    fill="clear"
                    expand="block"
                  >
                    {t("Read More")}
                  </IonButton>
                </IonCardContent>
              </IonCard>
            );
          });
        return <div>{list}</div>;
      }
    }
  }

export default NewsCards;