import React, { useContext, useState } from "react";
import {
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonInput,
  IonBackButton,
  IonButtons,
  IonAlert,
  IonLoading,
  IonIcon,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import ConsentFormPage from "./ConsentFormPage";
import { onIonPressEnter } from "../../Utils";
import BackendFactory from "../../API/BackendFactory";
import { UserInfoObject } from "../../API/lib/JsonUtils";
import GlobalContext from "../../GlobalContext";
import { refreshCircleOutline } from "ionicons/icons";
import getFlavoriaLang from "../../utils/flavoriaLang";
import i18next from "../../i18n";
function CodeVerficationPage() {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>();
  const [currentValue, setCurrentValue] = useState<string>("");
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const resendDuration = 59;
  const [timer, setTimer] = useState(resendDuration); // Initial timer value in seconds

  const context = useContext(GlobalContext);

  const nav = document.querySelector("ion-nav");

  const validateNumber = (num: string) => {
    return num != "";
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === "") return;
    const valid = validateNumber(value) !== null;

    if (valid) setCurrentValue(value);

    valid ? setIsValid(true) : setIsValid(false);
  };

  function confirmCurrent() {
    if (nav && isValid) {
      setShowLoading(true);
      BackendFactory.getBackend()
        .sendVerifyCode(currentValue)
        .then(() => {
          BackendFactory.getBackend()
            .getUserInfo()
            .then((result) => {
              const user = new UserInfoObject(result);
              if (user.getUserInfo().termsVersion >= 5) {
                // TODO: This could be better-written
                const accept = true;
                const wannaParticipate = true;
                const selectedResearchType = 3;
                localStorage.setItem("agreementAccepted", accept.toString());
                localStorage.setItem(
                  "acceptedResearchType",
                  selectedResearchType.toString()
                );
                localStorage.setItem(
                  "wannaParticipate",
                  wannaParticipate.toString()
                );
                context.setAgreementAccepted(accept);
                context.setAcceptedResearchType(selectedResearchType);
                context.setWannaParticipate(wannaParticipate);
                context.setEmail(localStorage.getItem("email") || "");
                setShowLoading(false);
              } else {
                nav.push(() => <ConsentFormPage />);
                setShowLoading(false);
              }
            })
            .catch(() => {
              setShowLoading(false);
              setShowError(true);
            });
        })
        .catch(() => {
          setShowLoading(false);
          setShowError(true);
        });
    }
  }

  function handleResendCode() {
    const email = localStorage.getItem("email");
    if (email == null) return;
    BackendFactory.getBackend()
      .sendCodeToEmail(email)
      .then(() => {
        // Disable the button
        setButtonDisabled(true);

        // Start the timer
        setTimer(resendDuration);
        const intervalId = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer === 1) {
              // Enable the button and clear the interval when the timer reaches 0
              setButtonDisabled(false);
              clearInterval(intervalId);
            }
            return prevTimer - 1;
          });
        }, 1000);
      });
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>{t("Verify Code")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        class="ion-padding"
        style={{ maxWidth: 576, alignSelf: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <h1>{t("Verify Code")}</h1>

          <h5 style={{ textAlign: "center", paddingBottom: "1em" }}>
            {t("E-Mail Address") + ": " + localStorage.getItem("email")}
          </h5>

          <IonInput
            onKeyUp={(e) => {
              onIonPressEnter(e, confirmCurrent);
            }}
            fill="solid"
            color="dark"
            onIonInput={(event) => validate(event)}
            inputMode="numeric"
            type="tel"
            label={t("Code:")}
            placeholder={t("Type the verification code here")}
          ></IonInput>
          <IonButton
            onClick={confirmCurrent}
            disabled={!isValid}
            expand="block"
            size="large"
            style={{ marginTop: ".5rem" }}
          >
            {t("Verify")}
          </IonButton>

          <IonButton
            id="resend-code"
            size="default"
            fill="clear"
            disabled={isButtonDisabled}
            style={{ paddingTop: 10 }}
          >
            <IonIcon icon={refreshCircleOutline} slot="start" />
            {isButtonDisabled
              ? t("Resend Code") + ` (${timer}s)`
              : t("Resend Code")}
            <IonAlert
              trigger="resend-code"
              header={t("Confirm")}
              subHeader={t("Send a new code")}
              message={t(
                "Are you sure you wish to erase the old code and receive a new code in the email?"
              )}
              buttons={[
                {
                  text: t("No"),
                  role: "cancel",
                },
                {
                  text: t("Yes"),
                  role: "confirm",
                  handler: handleResendCode,
                },
              ]}
            />
          </IonButton>

          <p>{t("Didn't get the code? Please also check your spambox.")} <a target="blank" href={"https://redcap.utu.fi/surveys/?s=DJ8EWJPLPAX7LAJP&__lang="+getFlavoriaLang(i18next.language)}>{t("Report an issue.")}</a></p>

          <IonAlert
            isOpen={showError}
            buttons={["OK"]}
            onDidDismiss={() => setShowError(false)}
            header={t("Incorrect Code")}
            subHeader={t("The code you entered is not valid.")}
            message={t(
              "Please verify that the code that you entered matches the code in your email."
            )}
          />
          <IonLoading
            isOpen={showLoading}
            message={t("Verifying...")}
            duration={10000}
          />
        </div>
      </IonContent>
    </>
  );
}

export default CodeVerficationPage;
