import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonRadioGroup,
  IonRadio,
  IonCheckbox,
  RadioGroupChangeEventDetail,
  RadioGroupCustomEvent,
  IonLoading,
  IonIcon,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../GlobalContext";
import BackendFactory from "../../API/BackendFactory";
import { openOutline } from "ionicons/icons";

export const ResearchType = {
  NONE: 0,
  SCIENTIFIC: 1,
  COMMERCIAL: 2,
  BOTH: 3,
};

function ConsentFormPage() {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>();
  const context = useContext(GlobalContext);

  const [wannaParticipate, setWannaParticipate] = useState<boolean>(
    context.wannaParticipate
  );
  const [accept, setAccept] = useState<boolean>(context.agreementAccepted);
  const [selectedResearchType, setSelectedResearchType] = useState(
    context.acceptedResearchType
  );
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      accept &&
      wannaParticipate &&
      selectedResearchType != ResearchType.NONE
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  });

  function radiobuttonchange(
    event: RadioGroupCustomEvent<RadioGroupChangeEventDetail<string>>
  ) {
    switch (event.target.value) {
      case "both":
        setSelectedResearchType(ResearchType.BOTH);
        break;
      case "commercial":
        setSelectedResearchType(ResearchType.COMMERCIAL);
        break;
      case "scientific":
        setSelectedResearchType(ResearchType.SCIENTIFIC);
        break;
      default:
        setSelectedResearchType(ResearchType.NONE);
        break;
    }
  }

  function handleAccept() {
    setShowLoading(true);
    BackendFactory.getBackend()
      .acceptUserAgreement(selectedResearchType)
      .then(() => {
        localStorage.setItem("agreementAccepted", accept.toString());
        localStorage.setItem(
          "acceptedResearchType",
          selectedResearchType.toString()
        );
        localStorage.setItem("wannaParticipate", wannaParticipate.toString());
        context.setAgreementAccepted(accept);
        context.setAcceptedResearchType(selectedResearchType);
        context.setWannaParticipate(wannaParticipate);
        context.setEmail(localStorage.getItem("email") || "");
        setShowLoading(false);
      });
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text=""></IonBackButton>
          </IonButtons>
          <IonTitle>{t("Agreement")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        class="ion-padding consentform"
        style={{ alignSelf: "center", maxWidth: 576 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <p>{t("I consent to my infomation being used for:")}</p>
          <IonRadioGroup class="radioButtonBorders" onIonChange={radiobuttonchange}>
            <IonRadio labelPlacement="end" value="commercial">
              <IonText class="ion-text-wrap">
                {t("Commercial Purposes")}
              </IonText>
            </IonRadio>
            <br />
            <IonRadio labelPlacement="end" value="scientific">
              <IonText class="ion-text-wrap">
                {t("Scientific Purposes")}
              </IonText>
            </IonRadio>
            <br />
            <IonRadio labelPlacement="end" value="both">
              <IonText class="ion-text-wrap">
                {t("Both Commercial and Scientific Purposes")}
              </IonText>
            </IonRadio>
          </IonRadioGroup>
          <br />
          <IonCheckbox
            checked={wannaParticipate}
            onIonChange={(e) => setWannaParticipate(e.target.checked)}
            labelPlacement="end"
            justify="start"
          >
            <IonText class="ion-text-wrap" className="multiline-checkbox">
              {t(
                "I have read the research announcement and I wish to participate in the study. (mandatory)"
              )}
            </IonText>
          </IonCheckbox>
          <IonCheckbox
            checked={accept}
            onIonChange={(e) => setAccept(e.target.checked)}
            labelPlacement="end"
            justify="start"
          >
            <IonText class="ion-text-wrap" className="multiline-checkbox">
              {t(
                "I allow my information to be handled as described before. (mandatory)"
              )}
            </IonText>
          </IonCheckbox>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignSelf: "left",
            }}
          >
            <IonButton
              href="https://www.flavoria.fi/wp-content/uploads/2023/08/2023-08-09-Flavoria-tutkimustiedote.pdf"
              target="_blank"
            >
              <IonText>
                {t("Open the Research Announcement (in Finnish)")}
              </IonText>
              <IonIcon slot="end" icon={openOutline} />
            </IonButton>
            <IonButton
              href="https://www.flavoria.fi/wp-content/uploads/2023/08/2023-08-09-Flavoria-tietosuojaseloste.pdf"
              target="_blank"
            >
              {t("Open the Privacy Policy (in Finnish)")}
              <IonIcon slot="end" icon={openOutline} />
            </IonButton>
            <IonButton onClick={handleAccept} disabled={!isValid}>
              {t("CONFIRM")}
            </IonButton>
          </div>
          <IonLoading
            isOpen={showLoading}
            message={t("Sending Confirmation to Servers...")}
            duration={10000}
          />
        </div>
      </IonContent>
    </>
  );
}

export default ConsentFormPage;
