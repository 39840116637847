import { instance as axios } from "./lib/axios/config.js";

import AbstractBackend from "./AbstractBackend";
import { AxiosError } from "axios";
import { format } from "date-fns";
import i18next from "../i18n";
import getFlavoriaLang from "../utils/flavoriaLang";


class MyFlavoriaBackend implements AbstractBackend {
  sendCodeToEmail(email: string): Promise<void> {
    return axios.post(`/auth/email`, { email: email }, { headers: {} });
  }

  async _getToken(code: string) {
    const response = await axios.post(`/auth/email/login`, {
      email: localStorage.getItem("email"),
      token: code,
    }); // expects json object containing authtoken and refreshtoken values
    if (response.status === 200) {
      //console.log(response.data);
      localStorage.setItem("token", response.data.authToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("authCodeAccepted", "true");
      //return response;
      return;
    } else if (response.status === 401) {
      localStorage.removeItem("email");
      throw "Try again later";
    }
  }

  sendVerifyCode(code: string): Promise<void> {
    return this._getToken(code);
  }

  fetchTermsOfUse(): Promise<string> {
    return axios.get(`https://api.myflavoria.fi/static/fi/terms-5/html`);
  }

  async _acceptUserAgreement(
    userAgreementVersion: number,
    researchType: number
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "X-Auth-Token": localStorage.getItem("token"),
      },
    };

    const payload = {
      researchNotify: true,
      privacyPolicy: true,
      type: researchType,
    };

    await axios.post(`/terms/accept/${userAgreementVersion}`, payload, config);
  }

  acceptUserAgreement(researchType: number): Promise<void> {
    return this._acceptUserAgreement(5, researchType);
  }

  async _getStaticContent(page: string) {
    try {
      const lang = getFlavoriaLang(i18next.language)
      const res = await axios.get(
        `https://api.myflavoria.fi/static/${lang}/${page}/html`
      );
      return res.data.html;
    } catch (error) {
      const aerror = error as AxiosError;
      if (aerror.request.status == 401) {
        throw "Expired Token";
      } else if (aerror.code === 'ECONNABORTED') {
        throw "Timeout"
      } else if (aerror.code === 'ERR_NETWORK') {
        throw "Offline"
      } else throw error;
    }
  }

  getStaticContent(page: string): Promise<string> {
    return this._getStaticContent(page);
  }

  async _fetch(address: string) {
    try {
      const res = await axios.get(address, {timeout: 5000});
      const ress = JSON.stringify(res.data);
      return ress;
    } catch (error) {
      const aerror = error as AxiosError;
      if (aerror.request.status == 401) {
        throw "Expired Token";
      } else if (aerror.code === 'ECONNABORTED') {
        throw "Timeout"
      } else if (aerror.code === 'ERR_NETWORK') {
        throw "Offline"
      } else throw error;
    }
  }

  async _post_nostr(address: string) {
    try {
      await axios.post(address, {timeout: 5000});
      return;
    } catch (error) {
      const aerror = error as AxiosError;
      if (aerror.request.status == 401) {
        throw "Expired Token";
      } else if (aerror.code === 'ECONNABORTED') {
        throw "Timeout"
      } else if (aerror.code === 'ERR_NETWORK') {
        throw "Offline"
      } else throw error;
    }
  }

  async _post_json(address: string, json_str: string) {
    try {
      //console.log(json_str);
      await axios.post(address, json_str, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return;
    } catch (error) {
      const aerror = error as AxiosError;
      if (aerror.request.status == 401) {
        throw "Expired Token";
      } else if (aerror.code === 'ECONNABORTED') {
        throw "Timeout"
      } else throw "Unexpected Error";
    }
  }

  getLunchList(date: string): Promise<string> {
    return this._fetch(`lunch-list/flavoria/${date}`);
  }

  getNewsFeed(lang: string): Promise<string> {
    if (lang != "fi")
      return this._fetch(`feed/newsfeed?lang=en`);
    else
      return this._fetch(`feed/newsfeed`);
  }

  getCurrentResearches(): Promise<string> {
    return this._fetch("researches/new");
  }

  getEarlierResearches(): Promise<string> {
    return this._fetch("researches/seen");
  }

  markResearchAsSeen(id: number): Promise<void> {
    return this._post_nostr(`/research/${id}/seen`);
  }

  getCoupons(): Promise<string> {
    return this._fetch("coupons/personal");
  }

  getAds(): Promise<string> {
    return this._fetch("coupons/ads");
  }

  getCampaign(): Promise<string> {
    return this._fetch("campaign/self");
  }

  getUserInfo(): Promise<string> {
    return this._fetch("user/self");
  }

  getVersionInfo(): Promise<string> {
    return this._fetch("version");
  }

  useCoupon(id: string): Promise<void> {
    return this._post_nostr(`coupon/${id}/use`);
  }

  getMealsDuringTimeperiod(start: Date, end: Date): Promise<string> {
    const startDate = format(start, "yyyy-MM-dd");
    const endDate = format(end, "yyyy-MM-dd");
    return this._fetch(`meals?beginDate=${startDate}&endDate=${endDate}`);
  }

  getMealExtrasTemplate(): Promise<string> {
    return this._fetch("meals/extras");
  }

  updateMealsSide(session_id: string, mealJSON: string) {
    return this._post_json(`/meal/${session_id}/extras`, mealJSON);
  }
}

export default MyFlavoriaBackend;
