import axios from "axios";

let isRefreshing = false;

export const instance = axios.create({
  baseURL: "https://api.myflavoria.fi",
  withCredentials: true,
});

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
  try {
    if (!isRefreshing) {
      axios.defaults.headers.common["X-Auth-Token-Refresh"] =
        localStorage.getItem("refreshToken");
      axios.defaults.headers.common["X-Auth-Token"] =
        localStorage.getItem("token");
      axios.defaults.headers.common["Content-Type"] =
        "application/json;charset=UTF-8";
      const response = await axios.get(
        "https://api.myflavoria.fi/auth/refresh"
      );
      const { authToken, refreshToken } = response.data;
      localStorage.setItem("token", authToken);
      localStorage.setItem("refreshToken", refreshToken);
      isRefreshing = false;
      return authToken;
    }
  } catch (error) {
    // Handle the error if the refresh token request fails
    //throw error;
    console.log(error);
  }
}

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Add the access token to the request headers
    config.headers["X-Auth-Token"] = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
instance.interceptors.response.use(
  (response) => {
    // Handle successful responses here
    return response;
  },
  async (error) => {
    const { data } = error.response
      ? error.response
      : { status: null, data: null };
    if (
      error.response &&
      error.response.status === 401 &&
      data.data === "TokenExpiredError"
    ) {
      // Token has expired; attempt to refresh it
      try {
        const newAccessToken = await refreshAccessToken();
        // Update the request configuration with the new access token
        const originalRequest = error.config;
        originalRequest.headers["X-Auth-Token"] = newAccessToken;
        // Retry the original request
        return axios(originalRequest);
      } catch (refreshError) {
        // Handle the error if token refresh fails
        //throw refreshError;
        console.log(refreshError);
      }
    }
    // Handle other response errors here
    return Promise.reject(error);
  }
);
