import React, { useContext, useRef, useState } from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import BackendFactory from "../API/BackendFactory";
import { endOfWeek, format, lastDayOfMonth, startOfWeek } from "date-fns";
import Debouncer from "../components/DebouncerUtility";
import { logout_sessionexpired } from "../logout";
import GlobalContext from "../GlobalContext";
import { PastMealsObject } from "../API/lib/JsonUtils";
import i18n from "../i18n";
import {
  MealItem,
  MealNutrients,
  WeeklyAverageMeal,
} from "../API/lib/DataTypes";
import FlavoriaPage from "../components/FlavoriaPage";
import { chevronDownCircle, helpCircle, informationCircle, openOutline, pencilOutline } from "ionicons/icons";
import NutrientDataPage from "../components/NutrientDataPage";
import FoodDataPage from "../components/FoodDataPage";
import MealExtrasDialog from "./MealExtrasDialog";
import ErrorCard from "../components/ErrorCard";
import SectionTitle from "../components/SectionTitle";

const debouncer = new Debouncer();
const cache : Record<string, string> = {};

const parseMeals = (mealsDuringTimeperiod: string) =>
  new PastMealsObject(mealsDuringTimeperiod).getPastMeals() as Record<
    string,
    MealItem
  >;

const Meals: React.FC = () => {
  const globalContext = useContext(GlobalContext);
  const [loadingState, setLoadingState] = useState(1);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [segment, setSegment] = useState("day");
  const [meals, setMeals] = useState<Record<string, MealItem>>();
  const BE = BackendFactory.getBackend();
  const [errorCode, setErrorCode] = useState("");

  const helpModal = useRef<HTMLIonModalElement>(null);

  debouncer.set_debouncer(() => {
    if (loadingState == 1) {
      const firstDate = firstDayOfMonth(currentDate);
      
      if (firstDate.getFullYear()+"-"+firstDate.getMonth() in cache) {
        setLoadingState(2);
        return;
      }

      BE.getMealsDuringTimeperiod(
        firstDate,
        lastDayOfMonth(currentDate)
      ).then((result) => {
            cache[firstDate.getFullYear()+"-"+firstDate.getMonth()] = result;
            setLoadingState(2);
        })
        .catch((reason) => {
          if (reason == "Expired Token") {
            logout_sessionexpired(globalContext, t);
          } else {
            if(typeof reason == "string")
              setErrorCode(reason)
            else
              setErrorCode("UnexpectedError");
  
            setLoadingState(0);
          }
        });
    } else if (loadingState == 2) {
      const firstDate = firstDayOfMonth(currentDate);
      const result = cache[firstDate.getFullYear()+"-"+firstDate.getMonth()];
      setMeals(parseMeals(result));
      setLoadingState(0)
    }
  });
  debouncer.call();

  function firstDayOfMonth(input: Date): Date {
    return new Date(input.getFullYear(), input.getMonth(), 1);
  }

  function deleteCurrentMonthCache() {
    const firstDate = firstDayOfMonth(currentDate);
    delete cache[firstDate.getFullYear()+"-"+firstDate.getMonth()];
  }

  const { t } = useTranslation();

  return (
    <FlavoriaPage pageID={"ACTIVE_PAGE_OWN_DATA"}>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          locale={i18n.language}
          onIonChange={(e) => {
            setCurrentDate(
              typeof e.target.value == "string"
                ? new Date(e.target.value)
                : new Date()
            );
            setLoadingState(1);
          }}
          presentation="month-year"
          id="datetime"
        ></IonDatetime>
      </IonModal>
      <div style={{ minHeight: 300, maxWidth: 1000, margin: "0 auto" }}>
      <IonSegment
        value={segment}
        onIonChange={(e) => setSegment(e.target.value + "" ?? "day")}
      >
        <IonSegmentButton value="day">{t("Day")}</IonSegmentButton>
        <IonSegmentButton value="week">{t("Week")}</IonSegmentButton>
        <IonSegmentButton value="month">{t("Month")}</IonSegmentButton>
      </IonSegment>

      <IonToolbar>

      <span style={{"marginLeft" : "10px"}}>{t("Timespan")}:<IonDatetimeButton color={"primary"} datetime="datetime" style={{ margin: ".5em", display: "inline-block"}}/></span>
      <IonButton slot="end" fill="clear" id="help-show" ><IonIcon slot="start" icon={helpCircle}></IonIcon>{t("Help")}</IonButton>
      </IonToolbar>

      <br />
      
        {meals && !loadingState && (
          <>
            {segment === "day" && (
              <DailyMeals
                updateCallback={() => {
                  deleteCurrentMonthCache()
                  setLoadingState(1)
                }}
                meals={meals}
              />
            )}
            {segment === "week" && <WeeklyMeals meals={meals} />}
            {segment === "month" && <MonthlyMeals meals={meals} />}
          </>
        )}

        {meals && !Object.entries(meals).length && !loadingState && (
          <div style={{ textAlign: "center" }}>
            {t("No meals were registered during this period.")}
          </div>
        )}

        {!!loadingState && (
          <>
            <IonItem>
              <IonLabel>{t("Loading...")}</IonLabel>
            </IonItem>
            <br/>
            <IonItem>
              <IonLabel><IonSkeletonText animated={true} /><br/></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel><IonSkeletonText animated={true} /><br/></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel><IonSkeletonText animated={true} /><br/></IonLabel>
            </IonItem>
          </>
        )}

        {
           (errorCode != "") && (
            <ErrorCard errorCode={errorCode}/>
           )
        }
        </div>
        <IonModal ref={helpModal} trigger="help-show">
        <IonHeader>
            <IonToolbar>
              <IonTitle>{t("Help")}</IonTitle>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => helpModal.current?.dismiss()}>
                  {t("Close")}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard style={{ "padding": "5px", margin: "0 auto"}}>
              <IonCardHeader><IonItem><IonIcon slot="start" size="large" icon={informationCircle}></IonIcon><IonCardTitle>{t("Nutrition Info & Recommendations")}</IonCardTitle></IonItem></IonCardHeader>
                <IonCardContent>
                    {
                      t("In the My Flavoria® app, you can view information about the nutritional content of the lunches you choose (the food you eat). You can view the most recent lunch, the average for the week and the average for the month.")
                    }
                    <br/>
                    <br/>

                    {
                      t("In order to obtain reliable weighing results, it is important to follow the instructions for using the weighing line carefully. The data for portions picked up from the grill, desserts, soups served in the cafeteria and packaged portion salads are based on a predefined portion size. In My Flavoria, the portion sizes of the side dishes to be added (breads, drinks, spreads, salad dressings) are also predefined. To ensure that the nutritional information for your lunch is recorded correctly, be sure to choose your side dishes carefully.")
                    }
                    <br/>
                    <br/>

                    {
                      t("The composition of a single lunch and its contribution to the daily diet can vary widely. Therefore, the results can only be compared with the recommendations as a guide.")
                    }
                    <br />
                    <br />
                    {
                      t("The nutritional information is based on data provided by Sodexo. As the distribution of biowaste in terms of components and therefore the exact composition is not known, the amount of biowaste has not been deducted from the nutrient content calculation results. However, the waste tracking provides the total amount of biowaste in grams and its share of the total weight of the lunch.")
                    }
                    <br />
                    <br />
                    {
                    t("The recommendations are based on Finnish Food Authority's nutrition and food recommendations.")}
                </IonCardContent>
              <IonButton fill="clear" expand="block" target="_blank" href={t("FFA_LINK")}>{t("Read More")}<IonIcon slot="end" icon={openOutline}/></IonButton>
            </IonCard>
          </IonContent>
        </IonModal>
    </FlavoriaPage>
  );
};

/*********************
 * UTILITY FUNCTIONS *
 *********************/
function groupMealsByWeeks(meals: Record<string, MealItem>) {
  const mealsByWeekNumbers = {} as Record<
    string,
    { meals: MealItem[]; weekStart: Date; weekEnd: Date }
  >;

  Object.keys(meals).forEach((date) => {
    const mealDate = new Date(date);
    let weekStart = startOfWeek(mealDate, { weekStartsOn: 1 });
    const weekNumber = format(weekStart, "w");

    // Ensure that the week start correctly represents the displayed data instead of going to the week on the previous month
    if (weekStart.getMonth() < mealDate.getMonth()) {
      weekStart = new Date(mealDate.getFullYear(), mealDate.getMonth(), 1);
    }
    let weekEnd = endOfWeek(mealDate);

    // Ensure that the week end correctly represents the displayed data instead of going to the week on the next month
    if (weekEnd.getMonth() > mealDate.getMonth()) {
      weekEnd = new Date(mealDate.getFullYear(), mealDate.getMonth() + 1, 0);
    }

    if (!mealsByWeekNumbers[weekNumber]) {
      mealsByWeekNumbers[weekNumber] = {
        meals: [],
        weekEnd,
        weekStart,
      };
    }
    mealsByWeekNumbers[weekNumber].meals.push(meals[date]);
  });

  // Get the averages
  const weeklyAverages = {} as Record<string, WeeklyAverageMeal>;
  Object.keys(mealsByWeekNumbers).forEach((weekNumber) => {
    const totals = mealsByWeekNumbers[weekNumber].meals.reduce(
      (acc, mealItem) => {
        (Object.keys(mealItem.nutrients) as (keyof MealNutrients)[]).forEach(
          (nutrient) => {
            if (!acc.nutrients[nutrient]) {
              acc.nutrients[nutrient] = 0;
            }
            acc.nutrients[nutrient] += mealItem.nutrients[nutrient] ?? 0;
          }
        );

        acc.totalWaste += mealItem.totalWaste ?? 0;
        acc.totalWeight += mealItem.totalWeight ?? 0;
        return acc;
      },
      { nutrients: {} as MealNutrients, totalWaste: 0, totalWeight: 0 }
    );

    const numberOfMeals = mealsByWeekNumbers[weekNumber].meals.length;
    const averageNutrients = { ...totals.nutrients };
    (Object.keys(averageNutrients) as (keyof MealNutrients)[]).forEach(
      (nutrient) => {
        averageNutrients[nutrient] = Number(
          (totals.nutrients[nutrient] / numberOfMeals).toPrecision(3)
        );
      }
    );

    const averageWaste = Number(
      (totals.totalWaste / numberOfMeals).toPrecision(3)
    );
    const averageWeight = Number(
      (totals.totalWeight / numberOfMeals).toPrecision(3)
    );

    weeklyAverages[weekNumber] = {
      nutrients: averageNutrients,
      numberOfMeals,
      totalWaste: averageWaste,
      totalWeight: averageWeight,
      weekStart: mealsByWeekNumbers[weekNumber].weekStart,
      weekEnd: mealsByWeekNumbers[weekNumber].weekEnd,
    };
  });
  return weeklyAverages;
}

/*********************
 *    MEAL VIEWS     *
 *********************/

function DailyMeals({
  meals,
  updateCallback,
}: {
  meals: Record<string, MealItem>;
  updateCallback: () => void;
}) {
  // Reverse the list so that newest is first
  const mealsList = Object.entries(meals);
  mealsList.reverse();
  if (!mealsList.length) {return (<></>)}
  return (
    <IonAccordionGroup
      style={{
        borderTop: "1px solid var(--ion-color-light-shade)",
        borderBottom: "1px solid var(--ion-color-light-shade)",
        margin: "1em",
      }}
    >
      {mealsList.map(([date, mealItem]) => (
        <DailyMealItemAccordion
          updateCallback={updateCallback}
          key={date}
          date={date}
          mealItem={mealItem}
        />
      ))}
    </IonAccordionGroup>
  );
}

// Not easily fixable problem: the first week and last week of a month might contain meals from
// two different months but we are only fetching one month worth of data at a time.
function WeeklyMeals({ meals }: { meals: Record<string, MealItem> }) {
  const { t } = useTranslation();
  const weeklyMeals = groupMealsByWeeks(meals);

  // Reverse the list so that newest is first
  const weeklyMealsList = Object.entries(weeklyMeals);
  weeklyMealsList.reverse();

  if (!weeklyMealsList.length) {return (<></>)}

  return (
    <div style={{ margin: "1em" }}>
      <h2>{t("Weekly average")}</h2>
      <IonAccordionGroup
        style={{ display: "flex", flexDirection: "column", gap: ".5em" }}
      >
        {weeklyMealsList.map(([weekNumber, mealsData]) => (
          <div
            key={weekNumber}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".25em",
            }}
          >
            <span
              style={{
                alignSelf: "flex-end",
                padding: ".5em",
                borderRadius: "5%",
                minWidth: "5em",
                display: "flex",
                justifyContent: "center",
                background: "var(--ion-color-primary)",
                color: "var(--ion-color-primary-contrast)",
              }}
            >{`${t("Week")} ${weekNumber}`}</span>
            <WeeklyMealItemAccordion
              weekNumber={weekNumber}
              mealsData={mealsData}
            />
          </div>
        ))}
      </IonAccordionGroup>
    </div>
  );
}

function MonthlyMeals({ meals }: { meals: Record<string, MealItem> }) {
  const { t } = useTranslation();

  const totalMeals = Object.keys(meals).reduce(
    (acc, date) => {
      const mealItem = meals[date];
      (Object.keys(mealItem.nutrients) as (keyof MealNutrients)[]).forEach(
        (nutrient) => {
          if (!acc.nutrients[nutrient]) {
            acc.nutrients[nutrient] = 0;
          }
          acc.nutrients[nutrient] += mealItem.nutrients[nutrient] ?? 0;
        }
      );

      acc.totalWaste += mealItem.totalWaste ?? 0;
      acc.totalWeight += mealItem.totalWeight ?? 0;
      return acc;
    },
    {
      nutrients: {} as MealNutrients,
      totalWaste: 0,
      totalWeight: 0,
    }
  );

  const numberOfMeals = Object.keys(meals).length;
  if (!numberOfMeals) {return (<></>)}

  const averageNutrients = { ...totalMeals.nutrients };
  (Object.keys(averageNutrients) as (keyof MealNutrients)[]).forEach(
    (nutrient) => {
      averageNutrients[nutrient] = Number(
        (totalMeals.nutrients[nutrient] / numberOfMeals).toPrecision(3)
      );
    }
  );

  const averageWaste = Number(
    (totalMeals.totalWaste / numberOfMeals).toPrecision(3)
  );
  const averageWeight = Number(
    (totalMeals.totalWeight / numberOfMeals).toPrecision(3)
  );

  return (
    <div
      style={{
        width: "100%",
        padding: "0 1em 1em 1em",
        display: "flex",
        gap: ".5em",
        flexDirection: "column",
      }}
    >
      <h2 style={{ marginTop: ".5em" }}>{t("Monthly average")}</h2>
      <p style={{ margin: "-.5em 0 -.5em 0" }}>
        {t("Number of meals")}: {numberOfMeals}
      </p>
      <NutrientDataPage
        mealItem={{
          nutrients: averageNutrients,
          totalWaste: averageWaste,
          totalWeight: averageWeight,
        }}
      />
    </div>
  );
}

/*********************
 *    ACCORDIONS     *
 *********************/

function DailyMealItemAccordion({
  date,
  mealItem,
  updateCallback,
}: {
  date: string;
  mealItem: MealItem;
  updateCallback: () => void;
}) {
  const { t } = useTranslation();
  const [isMealExtrasOpen, setIsMealExtrasOpen] = useState(
    location.hash.slice(0, 8) == "#extras-" &&
    location.hash.slice(8) == mealItem.sessionId
  );

  return (
    <IonAccordion value={date} toggleIcon={chevronDownCircle}>
      <IonItem slot="header">
        <IonLabel
          style={{
            fontWeight: "bold",
            paddingTop: "1em",
            paddingBottom: "1em",
          }}
        >
          {t("Meal on ")}{" "}
          {new Date(date).toLocaleDateString(i18n.language, {
            weekday: "long",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </IonLabel>
      </IonItem>
      <div
        slot="content"
        style={{
          width: "100%",
          padding: "1.25em 1em 1em 1em",
          display: "flex",
          gap: ".5em",
          flexDirection: "column",
        }}
      >
        <SectionTitle>{t("Summary")}</SectionTitle>
        <FoodDataPage mealItem={mealItem} />
        <IonButton expand="block" onClick={() => setIsMealExtrasOpen(true)}>
          <IonIcon slot="start" icon={pencilOutline}></IonIcon>
          {t("Set Meal Extras")}
          <MealExtrasDialog
            onDidDismiss={() => {
              setIsMealExtrasOpen(false);
              location.hash = "";
            }}
            onDidUpdate={() => updateCallback()}
            isOpen={isMealExtrasOpen}
            mealItems={mealItem.items}
            sessionId={mealItem.sessionId}
          />
        </IonButton>
        <NutrientDataPage mealItem={mealItem} />
      </div>
    </IonAccordion>
  );
}

function WeeklyMealItemAccordion({
  weekNumber,
  mealsData,
}: {
  weekNumber: string;
  mealsData: WeeklyAverageMeal;
}) {
  const { t } = useTranslation();
  return (
    <IonAccordion value={weekNumber} toggleIcon={chevronDownCircle}>
      <IonItem
        slot="header"
        style={{ borderTop: "1px solid var(--ion-color-light-shade)" }}
      >
        <IonLabel
          style={{
            fontWeight: "bold",
            paddingTop: "1em",
            paddingBottom: "1em",
            display: "flex",
            flexWrap: "wrap",
            gap: ".25em",
          }}
        >
          {t("Meals during")}
          <span>
            {mealsData.weekStart.toLocaleDateString(i18n.language)} -{" "}
            {mealsData.weekEnd.toLocaleDateString(i18n.language)}
          </span>
        </IonLabel>
      </IonItem>

      <div
        slot="content"
        style={{
          width: "100%",
          padding: "1.25em 1em 1em 1em",
          display: "flex",
          gap: ".5em",
          flexDirection: "column",
        }}
      >
        <p style={{ margin: "-.5em 0 -.5em 0" }}>
          {t("Number of meals")}: {mealsData.numberOfMeals}
        </p>
        <NutrientDataPage mealItem={mealsData} />
      </div>
    </IonAccordion>
  );
}

export default Meals;
