import React from 'react';

export default function enableMatomoTracing () {
  React.useEffect(() => {
   var _mtm = window._mtm = window._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src='https://mtm.utu.fi/js/container_2Tu3cbpx.js'; s.parentNode.insertBefore(g,s);
  }, [])
}

export function installMatomoTracing () {
   var _mtm = window._mtm = window._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src='https://mtm.utu.fi/js/container_2Tu3cbpx.js'; s.parentNode.insertBefore(g,s);    
}