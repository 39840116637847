import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { GlobalProvider } from "./GlobalContext";
import * as Sentry from "@sentry/react";
import errorList from "./utils/errorList";
import ErrorPage from "./pages/ErrorPage";
import { installMatomoTracing } from "./utils/matomo";

Sentry.init({
  dsn: "https://128763a473ab413b887bc6fe286d4010@sentry.tt.utu.fi/1",
  beforeSend(event) {
    if (!(event.extra && "userApproved" in event.extra)) {
        errorList.push(event)
        return null;
    }
    return event;
  },
  integrations: [
    Sentry.replayIntegration(),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Matomo is enabled for everyone as we cannot reliably disable it after it is enabled
installMatomoTracing();

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <I18nextProvider i18n={i18n}>
        <Sentry.ErrorBoundary showDialog fallback={({ error, componentStack }) => (<ErrorPage error={String(error)} componentStack={componentStack} />)}>
          <App />
        </Sentry.ErrorBoundary>
      </I18nextProvider>
    </GlobalProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
