import React, { useState } from "react";
import * as Sentry from "@sentry/react"
import errorList from "../utils/errorList";
import { IonAccordion, IonAccordionGroup, IonApp, IonButton, IonContent, IonItem, IonLabel, IonSpinner } from "@ionic/react";
import { useTranslation } from "react-i18next";
import MyFlavoriaError from "../assets/MyFlavoriaError.svg";
interface Props {
  error: string,
  componentStack: string
}

const SectionTitle: React.FC<Props> = (props: Props) => {
    const [reloadState,setReloadState] = useState(0)
    const { t } = useTranslation();
  return (
    <IonApp>
        <IonContent className="ion-padding">
            <center>
                <img src={MyFlavoriaError}></img>
            </center>
        <h1>{t("My Flavoria encounted an unexpected error and it has to be reloaded.")}</h1>
        <h3><b>{t("You can help by sending error data to My Flavoria team.")}</b></h3>
        <IonAccordionGroup style={{minHeight: "100px", "padding": "50px"}}>
            <IonAccordion>
            <IonItem slot="header" color="light">
          <IonLabel>{t("Details")}</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
            <h4>{props.error}</h4>
            {props.componentStack}
        </div>
        </IonAccordion>
        </IonAccordionGroup>
        <div style={{display: !reloadState ? "none" : "block"}}><IonItem><IonLabel>{t("Loading...")}</IonLabel><IonSpinner></IonSpinner></IonItem></div>
        <div style={{display: reloadState ? "none" : "block"}}>
            <IonButton
            color="success"
            expand="full"
            onClick={() => {
                setReloadState(2)
                while (errorList.length) {
                const last_error = errorList.pop()
                if(last_error) {
                    if (last_error.extra) {
                    last_error.extra["userApproved"] = true
                    } else {
                    last_error.extra = {"userApproved": true}
                    }
                    Sentry.captureEvent(last_error)
                }
            }
            setTimeout(() => location.reload(), 1000);
            }}
            >{t("Send Error Report and Reload the Page")}</IonButton>
            <IonButton
            expand="full"
            color="danger"
            onClick={() => {
            setReloadState(1)
            location.reload()
            }}
            >{t("Just Reload the Page, Do not Send Error Report")}</IonButton>
        </div>
        </IonContent>
  </IonApp>
    
  );
};

export default SectionTitle;
