import React, { useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import FlavoriaPage from "../components/FlavoriaPage";
import MyFlavoriaLogo from "../assets/my_flavoria_logo_white.png";
import i18n from "../i18n";
import { globeOutline } from "ionicons/icons";
import i18next from "../i18n";
import NewsCards from "../components/NewsCards";
import getFlavoriaLang from "../utils/flavoriaLang";

const Home: React.FC = () => {
  const { t } = useTranslation();
  const [hideLocalizationWarning, setHideLocalizationWarning] = useState(localStorage.getItem("hideLocalizationWarning") == "true");
  //const globalContext = useContext(GlobalContext);


  return (
    <FlavoriaPage className="home" pageID={"ACTIVE_PAGE_HOME"}>
      <center style={{ padding: "30px" }}>
        <img width="250" src={MyFlavoriaLogo}></img>
      </center>


      <IonCard style={{"padding": "5px", "display": (getFlavoriaLang(i18n.language) == "en" && !hideLocalizationWarning) ? "block" : "none"}}>
        <IonCardHeader><IonItem><IonIcon slot="start" size="large" icon={globeOutline}></IonIcon><IonCardTitle>{t("Experimental Localization")}</IonCardTitle></IonItem></IonCardHeader>
        <IonCardContent>
          <b>The English translation of My Flavoria is currently experimental. Some translations may be inaccurate or missing.</b>
        </IonCardContent>
        <IonButton fill="clear" expand="block" id="present-alert">More...</IonButton>
      </IonCard>
      <IonAlert
        trigger="present-alert"
        header="Experimental Localization"
        message="The English translation of My Flavoria is currently experimental. Some translations may be inaccurate or missing. You can switch your language in settings or you can press the button below to change the language to Finnish."
        inputs={[{id: "hide",type: 'checkbox', label: 'Do not show this warning anymore' }]}
        buttons={[
          {
            text: 'Switch to Finnish',
            role: 'destructive',
            handler: () => { i18next.changeLanguage('fi'); }
          }
          ,
          {
            text: 'OK',
            role: 'confirm'
          }
        ]
        }
        onDidDismiss={(event) => {
          if (!event.detail.data)
            return
          localStorage.setItem("hideLocalizationWarning", String(!!event.detail.data.values.length));
          setHideLocalizationWarning(!!event.detail.data.values.length)
        }}
      >
      </IonAlert>   



      <NewsCards language={i18n.language} />

    </FlavoriaPage>
  );
};

export default Home;