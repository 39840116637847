import React, { useRef } from "react";
import {
  IonButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import { informationCircle } from "ionicons/icons";

interface Props {
  showMealExtraPopup: boolean;
  message : string;
  onDidDismiss?: () => void;
}

const MealLogged: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }

  return (
    <IonModal
      ref={modal}
      style={{ "--height": "350px" }}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={props.showMealExtraPopup}
      onDidDismiss={() => {
        props.onDidDismiss ? props.onDidDismiss() : "";
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("Notification")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div
        style={{
          animationName: props.showMealExtraPopup ? "fadein" : "",
          animationDuration: "2s",
          animationTimingFunction: "ease-out",
        }}
      >
        <center style={{ padding: "30px" }}>
          <IonIcon color="warning" style={{fontSize: "84px"}} src={informationCircle}></IonIcon>
          <br />
          <br />
          <IonLabel>
            {props.message}
          </IonLabel>
          <br />
          <br />
        </center>
        <IonButton onClick={() => {dismiss()}} expand="full">
            {t("Dismiss")}
          </IonButton>
      </div>
    </IonModal>
  );
};

export default MealLogged;
