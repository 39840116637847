import { IonProgressBar } from "@ionic/react";
import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

const RECOMMENDED_NUTRIENT_AMOUNTS = {
    FAT: 60.0,
    FIBE: 25,
    PROT: 80,
    NACL: 5,
    CHOT: 250,
    ENER: 2000,
  } as const;
  
  const RECOMMENDED_NUTRIENT_AMOUNTS_RANGES = {
    FAT: "60-80",
    FIBE: "25-35",
    PROT: "80-100",
    NACL: "5",
    CHOT: "250-300",
    ENER: "2000-3000",
  } as const;

interface Props {
    amount: number;
    unit?: string;
    name: keyof typeof RECOMMENDED_NUTRIENT_AMOUNTS;
}

function getNutrientDataBarColor(recommendationRatio : number) : string {
    if (recommendationRatio < 1.0) {
      return "success"
    } else if (recommendationRatio < 1.2) {
      return "warning"
    } else {
      return "danger"
    }
  }

const NutrientData: React.FC<Props> = (props: Props) => {
        const { t } = useTranslation();
        const id = useId();
        const { ref, inView } = useInView({
            threshold: 0.5,
            triggerOnce: true
            });
        const value = inView && props.amount ? props.amount : 0;


  return (
    <div
    ref={ref}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "0.875em",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label htmlFor={id}>{t(props.name)}</label>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1em",
            width: "100%",
          }}
        >
          <IonProgressBar
            id={id}
            value={(value) / RECOMMENDED_NUTRIENT_AMOUNTS[props.name]}
            color={getNutrientDataBarColor ((value) / RECOMMENDED_NUTRIENT_AMOUNTS[props.name]) }
            style={{ height: "0.5em", marginTop: ".5em", width: "50%" }}
          />
          <div
            style={{ display: "flex", flexWrap: "wrap", justifyContent: "end" }}
          >
            <div>{value}/</div>
            <div>
              {RECOMMENDED_NUTRIENT_AMOUNTS_RANGES[props.name]} {props.unit}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutrientData;
