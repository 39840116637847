import { GlobalContextInterface } from "./GlobalContext";
import i18next from "./i18n";

function logout(userContext: GlobalContextInterface, reason?: string) {
  localStorage.setItem("agreementAccepted", "false");
  localStorage.setItem("acceptedResearchType", "0");
  localStorage.setItem("wannaParticipate", "false");
  userContext.setAgreementAccepted(false);
  userContext.setAcceptedResearchType(0);
  userContext.setWannaParticipate(false);
  if (reason !== undefined) {
    userContext.setLogoutReason(reason);
  }
}

export function logout_sessionexpired(
  userContext: GlobalContextInterface,
  translator: typeof i18next.t
) {
  logout(userContext, translator("Your session had expired."));
}

export default logout;
