import { useEffect, useState } from "react";
import requestBackgroundService from "../daemons/BackgroundServiceFactory";
import NewMealCheck from "../daemons/NewMealCheck";

function useNewMeals() {
  const [newMeals, setNewMeals] = useState<string | null>(null);
  useEffect(() => {
    requestBackgroundService("newMealCheck")?.addCallback((self) => {
      return new Promise((resolve) => {
        setNewMeals((self as NewMealCheck).newMealId);
        resolve(true);
      });
    });
  });

  return newMeals;
}

export default useNewMeals;
