export class LunchListObject {
  constructor(jsonstring) {
    this.courses = JSON.parse(jsonstring)[0].courses;
  }

  getTitles(lang) {
    return this.courses.map((course) => course[lang]["title"].toString());
  }

  pricesToString(student, other) {
    let result = "";
    result +=
      student !== undefined && student !== null
        ? student.toString() + "€ "
        : "";
    result +=
      other !== undefined && other !== null
        ? "/ " + other.toString() + "€"
        : "";
    return result;
  }

  getLunchItems(lang) {
    /**FIXME: This is good for now, but if there will be more languages this needs to be fixed **/
    if (["fi", "fi-FI"].includes(lang)) lang = "fi";
    else lang = "en";

    let result = [];
    for (let i = 0; i < this.courses.length; i++) {
      let course = this.courses[i];
      const lunchTitle = course[lang]["title"].toString();
      result.push({
        title:
          lunchTitle == ""
            ? course["fi"]["title"].toString()
            : lunchTitle /* Sometimes localizations may be empty, use finnish as a fallback */,
        key: i,
        prices: this.pricesToString(
          course["price"]["student"],
          course["price"]["other"]
        ),
        properties: course["properties"].map((prop) => prop.toString()),
        shortDesc: course["fi"]["desc"]
          ? course["fi"]["desc"].toString()
          : "" /* TODO: This does not seem to be localized currently */,
      });
    }
    return result;
  }
}

export class NewsListObject {
  constructor(jsonstring) {
    this.items = JSON.parse(jsonstring).items;
  }

  getNewsItems() {
    let result = [];
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      result.push({
        title: item["title"].toString(),
        key: i,
        text: item["description"].toString(),
        link: item["link"].toString(),
        date: item["pubdate"].toString()
      });
    }
    return result;
  }
}

export class ResearchListObject {
  constructor(jsonstring) {
    this.items = JSON.parse(jsonstring);
  }

  getResearchItems() {
    let result = [];
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      result.push({
        title: item["title"].toString(),
        key: i,
        link: item["uri"].toString(),
        desc: item["description"].toString(),
        id: item["id"],
        lang: item["lang"].toString()
      });
    }
    return result;
  }
}

export class CampaignListObject {
  constructor(jsonstring) {
    this.items = JSON.parse(jsonstring);
  }

  static getEmptyCampaign() {
      const result = {};
      result["key"] = 0;
      result["name"] = "";
      result["startDate"] = "";
      result["endDate"] = "";
      result["maxStamps"] = "";
      result["stampCount"] = "";

      result["content"] = {};
      result["content"]["stampCardTitle"] = {};
      result["content"]["dashboardDesc"] = {};
      result["content"]["stampCardTitle"]["en"] = ""
      result["content"]["stampCardTitle"]["fi"] = ""
      result["content"]["dashboardDesc"]["en"] = ""
      result["content"]["dashboardDesc"]["fi"] = ""

      return result;
  }

  getCampaigns() {
    return this.items.map((item, i) => {
      const result = {};
      result["key"] = i;
      result["name"] = item["name"];
      result["startDate"] = item["startDate"];
      result["endDate"] = item["endDate"];
      result["maxStamps"] = item["maxStamps"];
      result["stampCount"] = item["stampCount"];

      result["content"] = {};
      result["content"]["stampCardTitle"] = {};
      result["content"]["dashboardDesc"] = {};
      result["content"]["stampCardTitle"]["en"] =
        item["content"]["en"]["stampCardTitle"];
      result["content"]["stampCardTitle"]["fi"] =
        item["content"]["fi"]["stampCardTitle"];
      result["content"]["dashboardDesc"]["en"] =
        item["content"]["en"]["dashboardDesc"];
      result["content"]["dashboardDesc"]["fi"] =
        item["content"]["fi"]["dashboardDesc"];

      return result;
    });
  }
}

export class BenefitsListObject {
  constructor(jsonstring) {
    this.items = JSON.parse(jsonstring);
  }

  static groupItems(items) {
    let buckets = {};
    let loose = [];

    for (let i in items) {
      const item = items[i];
      if ("id" in item) {
        if ([item["id"]] in buckets) {
          buckets[item["id"]]["qty"] += 1;
        } else {
          buckets[item["id"]] = item;
          buckets[item["id"]]["qty"] = 1;
        }
      } else {
        loose.push(item);
      }
    }
    return Object.values(buckets).concat(loose);
  }

  getBenefits(locale) {
    let result = [];
    const groupedItems = BenefitsListObject.groupItems(this.items);
    for (let i = 0; i < groupedItems.length; i++) {
      let item = groupedItems[i];
      if (locale == "fi") {
        result.push({
          title: item["title"].toString(),
          key: i,
          desc: item["description"].toString(),
          couponId: "id" in item ? item["id"] : "",
          qty: "qty" in item ? item["qty"] : 1,
        });
      } else {
        result.push({
          title: item["i18n"]["en"]["title"].toString(),
          key: i,
          desc: item["i18n"]["en"]["description"].toString(),
          couponId: "id" in item ? item["id"] : "",
          qty: "qty" in item ? item["qty"] : 1,
        });
      }

    }
    return result;
  }
}

export class UserInfoObject {
  constructor(jsonstring) {
    this.info = JSON.parse(jsonstring);
  }

  getUserInfo() {
    return this.info;
  }
}
export class PastMealsObject {
  constructor(jsonstring) {
    try {
      this.info = JSON.parse(jsonstring);
    }
    catch (e) {
      this.info = {};
    }
  }

  getPastMeals() {
    try {
      let result = {};

      for (let date in this.info) {
        if (!this.info[date].length) continue;
  
        result[date] = {};
        result[date].sessionId = 0;
  
        result[date].nutrients = {};
        result[date].items = [];
  
        result[date].nutrients.FAT = 0.0;
        result[date].nutrients.PROT = 0.0;
        result[date].nutrients.CHOT = 0.0;
        result[date].nutrients.FIBE = 0.0;
        result[date].nutrients.NACL = 0.0;
        result[date].nutrients.ENKJ = 0.0;
        result[date].nutrients.ENER = 0.0;
        result[date].totalWeight = 0.0;
        result[date].totalWaste = 0.0;
  
        // For each session in a date
        for (let session in this.info[date]) {
          const nutrients = this.info[date][session].nutrients;
  
          // Add each session's items to the items array
          const newItems = result[date].items.concat(
            this.info[date][session].items
          );
  
          result[date].sessionId = this.info[date][session].sessionId;
          result[date].items = newItems;
          result[date].date = this.info[date][session].startTime;
          result[date].nutrients.FAT += nutrients["FAT"] | 0.0;
          result[date].nutrients.PROT += nutrients["PROT"] | 0.0;
          result[date].nutrients.CHOT += nutrients["CHOT"] | 0.0;
          result[date].nutrients.FIBE += nutrients["FIBE"] | 0.0;
          result[date].nutrients.NACL += nutrients["NACL"] | 0.0;
          result[date].nutrients.ENKJ += nutrients["ENKJ"] | 0.0;
          result[date].nutrients.ENER += nutrients["ENER"] | 0.0;
          result[date].totalWeight += this.info[date][session]["weight"] | 0.0;
          result[date].totalWaste += this.info[date][session]["waste"] | 0.0;
        }
      }
      return result;
    } catch(e) {
      return {}
    }

  }
}

export class ExtraTemplateObject {
  constructor(jsonstring) {
    this.items = JSON.parse(jsonstring);

    /* Translations for extras as they are not translated to english in the current backend */
    this.translationTable = {};

    this.translationTable["Kevyt maito"] = "Semi-skimmed Milk";
    this.translationTable["Rasvaton maito"] = "Skimmed Milk";
    this.translationTable["Rasvaton piimä"] = "Skimmed Buttermilk";
    this.translationTable["Soijajuoma"] = "Soy Drink";
    this.translationTable["Sekamehu"] = "Juice";

    this.translationTable["Näkkileipä"] = "Crispbread";
    this.translationTable["Tumma leipä"] = "Brown bread";
    this.translationTable["Vaalea leipä"] = "White bread";

    this.translationTable["Margariini"] = "Margarine";
    this.translationTable["Voi-kasviöljylevite"] =
      "Butter-vegetable Oil Spread";
    this.translationTable["Oliiviöljy"] = "Olive Oil";
    this.translationTable["Salaattikastike"] = "Salad dressing";

    this.translationTable["Krutongit"] = "Croutons";
    this.translationTable["Pähkinät ja siemenet"] = "Nuts and Seeds";

    this.translationTable["kpl"] = "pcs";
    this.translationTable["tl"] = "tsp";
    this.translationTable["rkl"] = "tbsp";

    this.translationTable["kappale"] = "piece";
    this.translationTable["viipale"] = "slice";
    this.translationTable["lasi (2dl)"] = "glass (2dl)";
    this.translationTable["(1tl, 5g)"] = "(1 tsp, 5g)";
    this.translationTable["(1rkl, 13g)"] = "(1 tbsp, 13g)";
    this.translationTable["(1rkl, 15g)"] = "(1 tbsp, 15g)";
    this.translationTable["(1rkl, 8g)"] = "(1 tbsp, 8g)";
    this.translationTable["(1rkl, 10g)"] = "(1 tbsp, 10g)";
  }

  translate(finnishName) {
    if (finnishName in this.translationTable)
      return this.translationTable[finnishName];
    else return finnishName;
  }

  getExtraTemplate() {
    const result = [];

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];

      result.push({
        id: item.id,
        name: {
          fi: item.name.fi,
          en:
            "en" in item.name
              ? item.name.en.toString()
              : this.translate(item.name.fi.toString()),
        },
        type: item.type,
        category: item.category,
        serving: {
          weight: item.serving.weight,
          description: {
            fi: item.serving.description.fi.toString(),
            en:
              "en" in item.serving.description
                ? item.serving.description.en.toString()
                : this.translate(item.serving.description.fi.toString()),
          },
          units: {
            fi: item.serving.units.fi,
            en:
              "en" in item.serving.units
                ? item.serving.units.en.toString()
                : this.translate(item.serving.units.fi.toString()),
          },
        },
        nutrients: {
          FAT: item.nutrients.FAT,
          PROT: item.nutrients.PROT,
          CHOT: item.nutrients.CHOT,
          FIBE: item.nutrients.FIBE,
          NACL: item.nutrients.NACL,
          ENKJ: item.nutrients.ENKJ,
          ENER: item.nutrients.ENER,
        },
      });
    }
    return result;
  }
}
