import React, { useContext, useState } from "react";
import {
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonInput,
  IonLoading,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonIcon,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import CodeVerficationPage from "./CodeVerificationPage";
import BackendFactory from "../../API/BackendFactory";
import { onIonPressEnter } from "../../Utils";
import GlobalContext from "../../GlobalContext";
import MyFlavoriaLogo from "../../assets/my_flavoria_logo_white.png";
import { settingsOutline } from "ionicons/icons";
import SettingsDialog from "../SettingsDialog";
function LoginPage() {
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const nav = document.querySelector("ion-nav");
  const backend = BackendFactory.getBackend();
  const context = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);

  const validateEmail = (email: string) => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === "") return;

    const valid = validateEmail(value) !== null;

    if (valid) localStorage.setItem("email", value);

    valid ? setIsValid(true) : setIsValid(false);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  function confirmCurrent() {
    const email = localStorage.getItem("email");
    if (nav && email && isValid) {
      setShowLoading(true);
      backend.sendCodeToEmail(email).then(() => {
        setShowLoading(false);
        nav.push(() => <CodeVerficationPage />);
      });
    }
  }

  function logoutReason(message: string) {
    return (
      <IonCard color="primary">
        <IonCardHeader>
          <IonCardTitle>{t("You were logged out automatically!")}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>{message}</IonCardContent>
      </IonCard>
    );
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("Login / Register")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        class="ion-padding"
        style={{ maxWidth: 576, alignSelf: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              style={{ userSelect: "none", maxHeight: 210 }}
              draggable="false"
              className="loginMyFlavoriaLogo"
              src={MyFlavoriaLogo}
            />
          </div>
          {context.logoutReason === ""
            ? ""
            : logoutReason(context.logoutReason)}

          <h1 style={{ marginTop: "3rem" }}>{t("Login / Register")}</h1>
          <p>{t("Welcome to My Flavoria app! Please register or log in by typing in your email and requesting a verification code.")}</p>
          <IonInput
            onKeyUp={(e) => {
              onIonPressEnter(e, confirmCurrent);
            }}
            className={`${isValid && "ion-valid"} ${
              isValid === false && "ion-invalid"
            } ${isTouched && "ion-touched"}`}
            fill="solid"
            color="dark"
            onIonInput={(event) => validate(event)}
            onIonBlur={() => markTouched()}
            errorText={t("Invalid E-Mail Address")}
            type="email"
            label={t("E-Mail Address")}
            placeholder={t("Type your e-mail address here")}
            labelPlacement="floating"
          ></IonInput>
          <IonButton
            onClick={confirmCurrent}
            disabled={!isValid}
            expand="block"
            size="large"
          >
            {t("Send Verification Code")}
          </IonButton>
          <br />
          <IonButton fill="clear" onClick={() => setIsOpen(true)}>
            <IonIcon slot="start" icon={settingsOutline} />
            {t("Settings")}
          </IonButton>
          <SettingsDialog
            hideClose={true}
            applyImmediately={true}
            isOpen={isOpen}
            onDidDismiss={() => setIsOpen(false)}
          />
          <IonLoading
            isOpen={showLoading}
            message={t("Sending verification code to e-mail...")}
            duration={10000}
          />
        </div>
      </IonContent>
    </>
  );
}

export default LoginPage;
