import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonSkeletonText,
} from "@ionic/react";

const TemplateCard: React.FC = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <IonSkeletonText animated={true} style={{ width: "100px" }} />
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <p>
          <IonSkeletonText
            style={{ margin: "10px", width: "90%" }}
            animated={true}
          />
          <IonSkeletonText
            style={{ margin: "10px", width: "60%" }}
            animated={true}
          />
        </p>
        <p>
          <IonSkeletonText
            style={{ margin: "10px", width: "100%" }}
            animated={true}
          />
          <IonSkeletonText style={{ margin: "10px", width: "20%" }} />
        </p>
      </IonCardContent>
    </IonCard>
  );
};

export default TemplateCard;
