import { IonBadge, IonIcon } from "@ionic/react";
import { happy, happyOutline } from "ionicons/icons";
import React from "react";

interface CampaignStampsProps {
    current: number;
    max: number;
  }

const CampaignStamps: React.FC<CampaignStampsProps> = (props : CampaignStampsProps) => {
    const array_c = Array(props.current).fill(true).concat(Array(props.max-props.current).fill(false)) 
  return (
  <div className="campaign-stamps">
    {
        array_c.map(
            (gottem, key) => {
                return (<IonIcon size="large" key={key} icon={gottem ? happy : happyOutline}></IonIcon>)
            }
        )
    }

    <span style={{margin:"10px"}}></span>
    <IonBadge> {props.current + " / " + props.max} </IonBadge>
  </div>
  );
};

export default CampaignStamps;
