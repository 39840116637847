import BackendFactory from "../API/BackendFactory";
import { MealItem } from "../API/lib/DataTypes";
import { PastMealsObject } from "../API/lib/JsonUtils";
import BackgroundService from "./BackgroundService";

const BE = BackendFactory.getBackend();

function getToday(): Date {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

class NewMealCheck extends BackgroundService {
  newMealId: string | null;

  constructor() {
    super((): Promise<void> => {
      return this.fetchMealsAndSetFlag();
    });
    this.newMealId = null;
    this.setFrequency(30000);
  }

  async fetchMealsAndSetFlag() {
    const today = getToday();

    /*TODO: This needs testing and fixing */
    const mealsDuringTimeperiod_func = async () => {try {return await BE.getMealsDuringTimeperiod(today, today)} catch (e) {return null;}};
    const mealsDuringTimeperiod = await mealsDuringTimeperiod_func();
    if (mealsDuringTimeperiod == null) return;
    
    const meals = new PastMealsObject(
      mealsDuringTimeperiod
    ).getPastMeals() as Record<string, MealItem>;
    const keys = Object.keys(meals);

    if (!keys.length) {
      this.newMealId = null;
      return;
    }

    const date = meals[keys[keys.length - 1]].date;

    if (!date) return;
    const now = new Date();
    const age = now.getTime() - new Date(date).getTime();

    if (age > 60 * 60 * 1000) {
      this.newMealId = null;
    } else {
      this.newMealId = meals[keys[keys.length - 1]].sessionId;
    }
  }
}

export default NewMealCheck;
