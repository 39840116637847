export interface MultilingualString {
  fi: string;
  en: string;
}

export function getLocalizedString(
  multilingualstr: MultilingualString,
  locale: string
): string {
  switch (locale) {
    case "fi":
    case "fi-FI":
      return multilingualstr.fi;
    default:
      return multilingualstr.en;
  }
}

export interface LunchItem {
  title: string;
  prices: string;
  properties: Array<string>;
  key: number;
  shortDesc: string;
}

export interface MealNutrients {
  FAT: number;
  PROT: number;
  CHOT: number;
  FIBE: number;
  NACL: number;
  ENKJ: number;
  ENER: number;
}

export interface NutritionInfo {
  nutrients: MealNutrients;
  totalWeight: number;
  totalWaste: number;
}

export interface MealItem extends NutritionInfo {
  items: Array<MealItemData>;
  date?: string;
  sessionId: string;
}

export interface WeeklyAverageMeal extends NutritionInfo {
  numberOfMeals: number;
  weekStart: Date;
  weekEnd: Date;
}

export interface MealItemData {
  id: string;
  type: "SIDE" | "extra";
  name: {
    fi: string;
    en: string;
  };
  weight: number;
  selectedQuantity: null | number;
  nutrients: MealNutrients;
}

export interface Recipe {
  name: string;
  ingredients: string;
  nutrients: string;
}

export interface ExtraTemplateItem {
  id: string;
  name: MultilingualString;
  type: string;
  category: string;
  serving: {
    weight: number;
    description: MultilingualString;
    units: MultilingualString;
  };
  nutrients: MealNutrients;
}

export interface TakenExtraItem extends ExtraTemplateItem {
  quantity: number;
}

export interface CampaignItem {
  key: string;
  name: string;
  startDate: string;
  endDate: string;
  maxStamps: string;
  stampCount: string;

  content: {
    stampCardTitle: MultilingualString;
    dashboardDesc: MultilingualString;
  };
}
