import { useTranslation } from "react-i18next";
import React from "react";
import SectionTitle from "../components/SectionTitle";
import { NutritionInfo } from "../API/lib/DataTypes";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem } from "@ionic/react";
import { VictoryPie } from "victory";
import { warning } from "ionicons/icons";
import FlavoriaPieGraph from "./FlavoriaPieGraph";
import NutrientData from "./NutrientData";

const FAT_COLOR = "var(--ion-color-danger)";
const PROT_COLOR = "var(--ion-color-warning)";
const CHOT_COLOR = "var(--ion-color-success)";

function Legend({ color, name }: { color: string; name: string }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        gap: ".5em",
        fontSize: ".875em",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "1em", height: "1em", background: color }}></div>
      {t(name)}
    </div>
  );
}

interface Props {
  mealItem: NutritionInfo;
}

const NutrientDataPage: React.FC<Props> = (props: Props) => {
  const mealItem = props.mealItem;

  const { t } = useTranslation();

  const { FAT, PROT, CHOT, FIBE, NACL, ENER } = mealItem.nutrients;

  const { totalWeight, totalWaste } = mealItem;

  const totalMainNutrients = FAT + PROT + CHOT;

  const noData = (
    <IonCard>
    <IonCardHeader><IonItem><IonIcon slot="start" size="large" icon={warning}></IonIcon><IonCardTitle>{t("No Additional Data Available")}</IonCardTitle></IonItem></IonCardHeader>
    <IonCardContent>
        {
          t("The session does not seem to have any data or items in it. It seems that you have registered an empty meal.")
        }
    </IonCardContent>
  </IonCard>     
);

  const nutrientData: VictoryPie["props"]["data"] = [
    { x: "FAT", y: FAT, fill: FAT_COLOR },
    {
      x: "PROT",
      y: PROT,
      fill: PROT_COLOR,
    },
    {
      x: "CHOT",
      y: CHOT,
      fill: CHOT_COLOR,
    },
  ].filter((i) => i.y > 0);


  if (nutrientData.length < 1) {
    return noData;
  }

  const recommendedNutrientData = [
    { x: "FAT", y: 30, fill: FAT_COLOR },
    { x: "PROT", y: 15, fill: PROT_COLOR },
    { x: "CHOT", y: 55, fill: CHOT_COLOR },
  ];

  const biowasteData = [
    {
      x: "Eaten",
      y: Math.min((totalWeight - totalWaste) / totalWeight, 1),
      fill: CHOT_COLOR,
    },
    {
      x: "Biowaste",
      y: Math.max(totalWaste / totalWeight, 0),
      fill: FAT_COLOR, 
    },
  ].filter((i) => i.y > 0);

  if (biowasteData.length < 1) {
    return noData;
  }
  return (
    <>
      <SectionTitle>{t("Daily nutrient intake")}</SectionTitle>
      <NutrientData amount={ENER} unit="kcal" name={"ENER"} />
      <NutrientData amount={FAT} name={"FAT"} />
      <NutrientData amount={CHOT} name={"CHOT"} />
      <NutrientData amount={PROT} name={"PROT"} />
      <NutrientData amount={FIBE} name={"FIBE"} />
      <NutrientData amount={NACL} name={"NACL"} />
      <SectionTitle>{t("Energy composition")}</SectionTitle>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: 375,
          width: "100%",
          margin: "0 auto",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <h4 style={{ fontSize: "1.125em" }}>{t("Your Meal")}</h4>
          <div
            style={{
              maxWidth: 128,
            }}
          >
              <FlavoriaPieGraph multiplier={100} divisor={totalMainNutrients} data={nutrientData}/>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <h4 style={{ fontSize: "1.125em" }}>{t("Recommended")}*</h4>
          <div
            style={{
              maxWidth: 128,
            }}
          >
            <FlavoriaPieGraph data={recommendedNutrientData}/>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: ".5em",
            margin: "0 auto",
          }}
        >
          <Legend name="FAT" color={FAT_COLOR} />
          <Legend name="PROT" color={PROT_COLOR} />
          <Legend name="Carbohydrate" color={CHOT_COLOR} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "0 auto",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
      </div>
      <p>* {t("The recommendations are based on Finnish Food Authority's nutrition and food recommendations.")}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <SectionTitle>{t("Biowaste monitoring")}</SectionTitle>
        <div
          style={{
            maxWidth: 128,
          }}
        >
          <FlavoriaPieGraph multiplier={100} data={biowasteData}/>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: ".5em",
          margin: "0 auto",
        }}
      >
        <Legend name="Eaten" color={CHOT_COLOR} />
        <Legend name="Biowaste" color={FAT_COLOR} />
      </div>
    </>
  );
};

export default NutrientDataPage;
