import React, { useContext, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonModal,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonToggle,
  IonIcon,
  IonToast,
} from "@ionic/react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import GlobalContext from "../GlobalContext";
import {
  brushOutline,
  informationCircleOutline,
  languageOutline,
  moonOutline,
  sunnyOutline,
} from "ionicons/icons";
import getFlavoriaLang from "../utils/flavoriaLang";
import SoftwareInformation from "../components/SoftwareInformation";

type CallbackFunction = () => void;

interface Props {
  trigger?: string;
  isOpen?: boolean;
  onDidDismiss?: CallbackFunction;
  applyImmediately?: boolean;
  hideClose?: boolean;
}

const settingsDialog: React.FC<Props> = (props: Props) => {
  const settingsModal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const [refreshOnApply, setRefreshOnApply] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showSoftwareInfo, setShowSoftwareInfo] = useState<boolean>(false);

  function handleDismiss() {
    if (refreshOnApply) window.location.reload();

    if (props.onDidDismiss != null) props.onDidDismiss();
  }

  function setDarknessPreference(preference: string) {
    localStorage.setItem("themeDarknessPrefrence", preference);
    globalContext.setThemeDarknessPrefrence(preference);
  }

  function getStylePrefrence() {
    const pref = localStorage.getItem("stylePrefrence");
    if (pref === "md" || pref === "ios") return pref;
    else return "system";
  }

  function toastShowMessage(message: string) {
    setToastMessage(message);
    setShowToast(true);
  }

  function languageFormat(languageId: string, localName: string) {
    const translated = t(languageId);
    if (translated == localName) return localName;
    else return t(languageId) + " — " + localName;
  }

  return (
    <IonModal
      ref={settingsModal}
      style={{ "--height": "330px" }}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      trigger={props.trigger}
      isOpen={props.isOpen}
      onDidDismiss={() => handleDismiss()}
    >
      <IonToast
        isOpen={showToast}
        message={toastMessage}
        duration={1500}
        onDidDismiss={() => setShowToast(false)}
      />

      <IonModal onDidDismiss={() => setShowSoftwareInfo(false)} isOpen={showSoftwareInfo}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t("About (Software Info)")}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setShowSoftwareInfo(false)
                }}
              >
                {t("Close")}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
            <SoftwareInformation />
          </IonContent>
      </IonModal>

      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("Settings")}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              style={{ display: props.hideClose ? "none" : "inline" }}
              onClick={() => {
                settingsModal.current?.dismiss();
              }}
            >
              {t("Close")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonIcon slot="start" icon={languageOutline} />
            <IonSelect
              value={getFlavoriaLang(i18next.language)}
              okText={t("Ok")}
              cancelText={t("Cancel")}
              onIonChange={(e) => {
                i18next.changeLanguage(e.detail.value);
                //setRefreshOnApply(true); //Language change may require refresh, but should work fine without
              }}
              label={t("Language")}
            >
              <IonSelectOption value="en">
                {languageFormat("LANG_ENGLISH", "English")}
              </IonSelectOption>
              <IonSelectOption value="fi">
                {languageFormat("LANG_FINNISH", "Suomi")}
              </IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonIcon slot="start" icon={brushOutline} />
            <IonSelect
              onIonChange={(e) => {
                localStorage.setItem("stylePrefrence", e.target.value);
                if (props.applyImmediately) {
                  window.location.reload();
                } else {
                  toastShowMessage(
                    t(
                      "New styles will be applied after the settings dialog is closed."
                    )
                  );
                  setRefreshOnApply(true);
                }
              }}
              label={t("Style")}
              labelPlacement="fixed"
              value={getStylePrefrence()}
            >
              <IonSelectOption value="system">
                {t("System Default")}
              </IonSelectOption>
              <IonSelectOption value="md">Material (Android)</IonSelectOption>
              <IonSelectOption value="ios">Aqua (iOS)</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonIcon slot="start" icon={sunnyOutline} />
            <IonToggle
              onIonChange={(e) => {
                setDarknessPreference(e.target.checked ? "light" : "auto");
              }}
              checked={globalContext.themeDarknessPrefrence == "light"}
            >
              {t("Prefer Light Theme")}
            </IonToggle>
          </IonItem>

          <IonItem>
            <IonIcon slot="start" icon={moonOutline} />
            <IonToggle
              onIonChange={(e) => {
                setDarknessPreference(e.target.checked ? "dark" : "auto");
              }}
              checked={globalContext.themeDarknessPrefrence == "dark"}
            >
              {t("Prefer Dark Theme")}
            </IonToggle>
          </IonItem>

          <IonItem button={true} onClick={() => setShowSoftwareInfo(true)}>
            <IonIcon slot="start" icon={informationCircleOutline} />
              {t("About (Software Info)")}
          </IonItem>

        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default settingsDialog;
