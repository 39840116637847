import React, { useContext, useState } from "react";
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import TemplateCard from "../components/TemplateCard";
import FooterPadding from "../components/FooterPadding";
import AbstractBackend from "../API/AbstractBackend";
import BackendFactory from "../API/BackendFactory";
import { logout_sessionexpired } from "../logout";
import GlobalContext from "../GlobalContext";
import { BenefitsListObject, CampaignListObject } from "../API/lib/JsonUtils";
import PersonalQR from "../components/PersonalQrCode";
import Debouncer from "../components/DebouncerUtility";
import TimerCountDown from "../components/Countdown";
import FlavoriaPage from "../components/FlavoriaPage";
import { debugCoupons } from "../API/Debug.config";
import { CampaignItem, getLocalizedString } from "../API/lib/DataTypes";
import i18n from "../i18n";
import ErrorCard from "../components/ErrorCard";
import i18next from "../i18n";
import CampaignStamps from "../components/CampaignStamps";
import getFlavoriaLang from "../utils/flavoriaLang";
const debouncer = new Debouncer();

const Benefits: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const [campaigns, setCampaigns] = useState("");
  const [ads, setAds] = useState("");
  const [coupons, setCoupons] = useState("");
  const [currentCampaign, setCurrentCampaign] = useState("");
  const [currentlySelected, setCurrentlySelected] = useState("");
  const [currentlyActive, setCurrentlyActive] = useState("");
  const globalContext = useContext(GlobalContext);

  function renderCards() {
    if (loading) {
      return (
        <div>
          <TemplateCard />
          <TemplateCard />
          <TemplateCard />
        </div>
      );
    } else if (errorCode != ""){
      return (<ErrorCard errorCode={errorCode}/>)
    } else {
      if (
        (coupons === "[]" || coupons === "") &&
        (ads === "[]" || ads === "") &&
        (campaigns === "[]" || campaigns === "")
      ) {
        return (
          <IonCard>
            <IonCardHeader>
              <IonTitle>{t("No benefits currently available.")}</IonTitle>
            </IonCardHeader>
          </IonCard>
        );
      } else {
        const coupons_objs = new BenefitsListObject(coupons);
        const ad_objs = new BenefitsListObject(ads);
        const campaign_objs = new CampaignListObject(campaigns);

        const campaign_list: Array<React.ReactElement> = (
          campaign_objs.getCampaigns() as Array<CampaignItem>
        ).map((cp) => {
          return (
            <IonItem onClick={() => {setCurrentCampaign(cp["key"]);setShowCampaignModal(true)}} key={"campaign-" + cp["key"]} button={true}>
              <IonLabel>
                <h3>
                  {getLocalizedString(
                    cp["content"]["stampCardTitle"],
                    i18n.language
                  )}
                </h3>
                <p>
                  {getLocalizedString(
                    cp["content"]["dashboardDesc"],
                    i18n.language
                  )}
                </p>
              </IonLabel>
              <IonBadge slot="end">
                {cp["stampCount"] + " / " + cp["maxStamps"]}
              </IonBadge>
            </IonItem>
          );
        });

        const coupon_list: Array<React.ReactElement> = coupons_objs
          .getBenefits(getFlavoriaLang(i18next.language))
          .map((cp): React.ReactElement => {
            return (
              <IonItem
                onClick={() => {
                  setShowModal(true);
                  setCurrentlySelected(cp.couponId);
                }}
                button={cp.couponId !== ""}
                key={cp.key}
              >
                <IonLabel>
                  <h3>{cp.title}</h3>
                  <p>{cp.desc}</p>
                </IonLabel>
                <IonBadge
                  color={"success"}
                  style={{
                    visibility:
                      cp.couponId == currentlyActive ? "visible" : "hidden",
                  }}
                >
                  <TimerCountDown
                    running={cp.couponId == currentlyActive}
                    initialSecondsRemaining={300}
                  ></TimerCountDown>
                </IonBadge>
                <IonBadge
                  style={{ visibility: cp.qty == 1 ? "hidden" : "visible" }}
                  slot="end"
                >
                  {"x" + cp.qty}
                </IonBadge>
              </IonItem>
            );
          });
        const ad_list: Array<React.ReactElement> = ad_objs
          .getBenefits(getFlavoriaLang(i18next.language))
          .map((cp): React.ReactElement => {
            return (
              <IonItem
                onClick={() => setShowModal(true)}
                button={cp.couponId !== ""}
                key={cp.key}
              >
                <IonLabel>
                  <h3>{cp.title}</h3>
                  <p>{cp.desc}</p>
                </IonLabel>
                <IonBadge
                  style={{ visibility: cp.qty == 1 ? "hidden" : "visible" }}
                  slot="end"
                >
                  {"x" + cp.qty}
                </IonBadge>
              </IonItem>
            );
          });

        return (
          <IonList>{campaign_list.concat(coupon_list).concat(ad_list)}</IonList>
        );
      }
    }
  }

  function useCurrentCoupon() {
    const BE: AbstractBackend = BackendFactory.getBackend();
    if (currentlyActive == currentlySelected) return;
    if (debugCoupons) {
      console.log(currentlySelected);
      setCurrentlyActive(currentlySelected);
    } else {
      BE.useCoupon(currentlySelected).then(() => {
        setCurrentlyActive(currentlySelected);
      });
    }
  }

  function getCurrentlySelectedDescription() {
    try {
      const coupons_objs = new BenefitsListObject(coupons).getBenefits();
      for (let i = 0; i < coupons_objs.length; i++) {
        const coupon = coupons_objs[i];
        if (coupon.couponId == currentlySelected) {
          return (
            <>
              <h1>{coupon.title}</h1>
              <p>{coupon.desc}</p>
            </>
          );
        }
      }
    } catch (error) {
      return <span></span>;
    }
  }

  debouncer.set_debouncer(() => {
    const BE: AbstractBackend = BackendFactory.getBackend();
    Promise.all([BE.getCoupons(), BE.getAds(), BE.getCampaign()])
      .then((results) => {
        setCoupons(results[0]);
        setAds(results[1]);
        setCampaigns(results[2]);
        setLoading(false);
      })
      .catch((reason) => {
        if (reason == "Expired Token") {
          logout_sessionexpired(globalContext, t);
        } else {
          if(typeof reason == "string")
            setErrorCode(reason)
          else
            setErrorCode("UnexpectedError");

          setLoading(false);
        }
      });
  });

  if (loading) {
    debouncer.call();
  }
  
  const cmp = (function() {
    try {
        const cmps = new CampaignListObject(campaigns).getCampaigns();
        for (const i in cmps) {
          if(cmps[i]["key"] == currentCampaign) {
            return cmps[i];
          }
        }
    } catch (e : unknown) {
      //pass
    }
    return CampaignListObject.getEmptyCampaign();
  })()

  return (
    <FlavoriaPage pageID={"ACTIVE_PAGE_BENEFITS"}>
      {renderCards()}
      <FooterPadding />
      <IonModal
        keepContentsMounted={true}
        onDidDismiss={() => setShowModal(false)}
        isOpen={showModal}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>
                {t("Dismiss")}
              </IonButton>
            </IonButtons>
            <IonTitle>{t("Coupon")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <PersonalQR visible={currentlySelected == currentlyActive} />
          <span
            style={{
              display: currentlySelected == currentlyActive ? "inline" : "none",
            }}
          >
            <br />
            <center>
              <b>
                <TimerCountDown
                  style={{
                    fontSize: "20pt",
                    color: "var(--ion-color-danger)",
                    userSelect: "none",
                  }}
                  running={currentlyActive !== ""}
                  initialSecondsRemaining={300}
                />
              </b>
            </center>
            <IonText>
              <h1>
                {t(
                  "Coupon has been now activated and it is available for 5 minutes"
                )}
              </h1>
              <p>{t("Please show the QR-code to the QR-reader.")}</p>
            </IonText>
          </span>
          <IonText>{getCurrentlySelectedDescription()}</IonText>
          <span
            style={{
              display: currentlySelected != currentlyActive ? "inline" : "none",
            }}
          >
            <p>
              {t(
                "After the coupon has been used, it is available for 5 minutes. It has to be shown during the purchase."
              )}
            </p>
            <IonButton
              onClick={() => {
                if (currentlyActive !== "") {
                  setShowAlert(true);
                } else {
                  useCurrentCoupon();
                }
              }}
              style={{ width: "100%" }}
            >
              {t("Use")}
            </IonButton>
            <IonAlert
              header="Confirm"
              buttons={[
                {
                  text: t("Cancel"),
                  role: "cancel",
                },
                {
                  text: t("Replace"),
                  role: "confirm",
                  cssClass: "alert-button-danger",
                  handler: () => {
                    useCurrentCoupon();
                  },
                },
              ]}
              isOpen={showAlert}
              subHeader={t(
                "There is currently another coupon in use. Discard the old coupon and replace it with this one?"
              )}
              onDidDismiss={() => setShowAlert(false)}
            />
          </span>
        </IonContent>
      </IonModal>
      <IonModal
        onDidDismiss={() => setShowCampaignModal(false)}
        isOpen={showCampaignModal}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowCampaignModal(false)}>
                {t("Dismiss")}
              </IonButton>
            </IonButtons>
            <IonTitle>{t("Campaign")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonText>
            <h1>{getLocalizedString(
                    cmp["content"]["stampCardTitle"],
                    i18n.language
            )}</h1>
            <p>
            {getLocalizedString(
                    cmp["content"]["dashboardDesc"],
                    i18n.language
                  )}
            </p>
            <br />
            <center>
              <CampaignStamps current={cmp["stampCount"]} max={cmp["maxStamps"]}/>
            </center>
            <br/>
            <p>{t("You have currentStamps out of maxStamps stamps. Collect maxStamps to get a reward.", {currentStamps: cmp["stampCount"], maxStamps: cmp["maxStamps"]})}</p>
          </IonText>
        </IonContent>
      </IonModal>
    </FlavoriaPage>
  );
};

export default Benefits;
