import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const SectionTitle: React.FC<Props> = (props: Props) => {
  return (
    <h3
      className="sectionTitle"
      style={{
        margin: ".5em 0",
        padding: ".5em .25em",
        fontSize: "1em",
        width: "100%",
      }}
    >
      {props.children}
    </h3>
  );
};

export default SectionTitle;
