function getSelfID() : string | null {
    try {
        const token = localStorage.getItem("token");
        if (token == null) {throw "No token"}
        const token_split = token.split(".");
        return JSON.parse(atob(token_split[1]))["id"];
    } catch {
        return null
    }
}

export default getSelfID;