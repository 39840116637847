//almipap: Appparently in JavaScript/TypeScript you can't use functions as a key, so we have to use list O(n) instead of hashmap O(1). :/

type promise_func<T> = () => Promise<T>;

interface promise_cache<T> {
  func: promise_func<T>;
  retval: Promise<T>;
}

const _loadoncelist: Array<promise_cache<unknown>> = [];

function loadOnlyOnce<T>(fun: promise_func<T>): Promise<T> {
  const cache = _loadoncelist.find((value) => value.func == fun);
  if (cache) {
    return cache.retval as Promise<T>;
  } else {
    const retval = fun();
    _loadoncelist.push({ func: fun, retval: retval });
    return retval;
  }
}

export default loadOnlyOnce;
