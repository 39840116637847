import BackendFactory from "../API/BackendFactory"
import buildInfo from "../../package.json"
import { platformType } from "./abstraction";

let valid_data = false;
let raw_string = ""

export async function isUpToDate() /* Promise<boolean?> */ {  
  if(!valid_data) {
    try {
      raw_string = await BackendFactory.getBackend().getVersionInfo();
      valid_data = true;
    } catch (e) {
      valid_data = false;
    } 
  }

  let json_data = JSON.parse(raw_string);
  let platform = platformType()
  
  if (!valid_data) return null; 

  if (platform == "ios")
    return false;
  else if(platform == "android")
    return false;
  else
    return json_data["pwa-version"] == buildInfo.version;
}