import React, { useContext, useEffect } from "react";
import { IonContent } from "@ionic/react";
import HeaderPadding from "./HeaderPadding";
import FooterPadding from "./FooterPadding";
import GlobalContext from "../GlobalContext";

interface Props {
  children: React.ReactElement[] | React.ReactElement;
  pageID: string;
  className?: string;
}
const FlavoriaPage: React.FC<Props> = (props: Props) => {
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    globalContext.setActivePage(props.pageID);
  });

  return (
    <IonContent className={"flavoriapage "+props.className}>
      <HeaderPadding />
      {props.children}
      <FooterPadding />
    </IonContent>
  );
};

export default FlavoriaPage;
