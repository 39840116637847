import React from "react";
import { VictoryContainer, VictoryPie } from "victory";
import { useInView } from "react-intersection-observer";

interface Props {
  multiplier?: number;
  data: unknown[];
  divisor?: number
}

const LBL_COLOR = "black"

const placeholderData = [
    { x: "", y: 0, fill: "black" }
  ];

const FlavoriaPieGraph: React.FC<Props> = (props: Props) => {
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
      });

    const multiplier = props.multiplier || 1.0;
    const divisor = props.divisor || 1.0
  return (
    <span style={{opacity: inView ? 1.0 : 0.0}} ref={ref}>
    <VictoryPie
            width={128}
            height={128}
            padding={0}
            animate={{ duration: 500 }}
            containerComponent={
              <VictoryContainer
                style={{
                  pointerEvents: "auto",
                  userSelect: "auto",
                  touchAction: "auto",
                }}
              />
            }
            style={{
              labels: {
                fill: LBL_COLOR
              },
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            data={inView ? props.data : placeholderData}
            labels={({ datum }) => `${(datum.y / divisor * multiplier).toFixed(1)}%`}
            labelRadius={24}
          />
          </span>
  );
};

export default FlavoriaPieGraph;
