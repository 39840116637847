import React from "react";
import {
  IonButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import { checkmarkCircle } from "ionicons/icons";

interface Props {
  showMealExtraPopup: boolean;
  mealId?: string;
  onDidDismiss?: () => void;
}

const MealLogged: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <IonModal
      style={{ "--height": "350px" }}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={props.showMealExtraPopup}
      onDidDismiss={() => {
        props.onDidDismiss ? props.onDidDismiss() : "";
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("Meals Successfully Logged")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div
        style={{
          animationName: props.showMealExtraPopup ? "fadein" : "",
          animationDuration: "2s",
          animationTimingFunction: "ease-out",
        }}
      >
        <center style={{ padding: "30px" }}>
          <IonIcon color="success" style={{fontSize: "84px"}} src={checkmarkCircle}></IonIcon>
          <br />
          <br />
          <IonLabel>
            {t(
              "Your meals have been logged to the database. Remember to log your side dishes in the Data-section."
            )}
          </IonLabel>
          <br />
          <br />
          <IonButton href={"meals#extras-" + props.mealId} expand="full">
            {t("Log Side Dishes")}
          </IonButton>
        </center>
      </div>
    </IonModal>
  );
};

export default MealLogged;
