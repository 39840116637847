import React from "react";

/**
 * Based on a customizable countdown component for React Native By: Avid21
 * URL: https://github.com/avid21/react-native-timer-countdown
 */

interface TimerCountDownProps {
  running?: boolean;
  initialSecondsRemaining: number;
  interval?: number;
  formatSecondsRemaining?: (secs: number) => string;
  onTick?: (secs: number) => void;
  onTimeElapsed?: () => void;
  style?: React.CSSProperties;
}

interface TimerCountDownState {
  milliSecondsRemaining: number;
  timeoutId: NodeJS.Timeout | null;
  previousMilliSeconds: number | null;
}

export default class TimerCountDown extends React.Component<
  TimerCountDownProps,
  TimerCountDownState
> {
  mounted = false;

  constructor(props: TimerCountDownProps) {
    super(props);
    this.state = {
      milliSecondsRemaining: this.props.initialSecondsRemaining * 1000,
      timeoutId: null,
      previousMilliSeconds: null,
    };

    this.tick = this.tick.bind(this);
    this.getFormattedTime = this.getFormattedTime.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.tick();
  }

  //componentWillReceiveProps(newProps : TimerCountDownProps) {
  //  if (this.state.timeoutId) { clearTimeout(this.state.timeoutId); }
  //  this.setState({ previousSeconds: null, secondsRemaining: newProps.initialSecondsRemaining });
  // }

  componentDidUpdate() {
    if (
      !this.state.previousMilliSeconds &&
      this.state.milliSecondsRemaining > 0 &&
      this.mounted &&
      this.props.running
    ) {
      this.tick();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.state.timeoutId != null) clearTimeout(this.state.timeoutId);
  }

  tick() {
    const currentMilliSeconds = Date.now();
    const dt = this.state.previousMilliSeconds
      ? currentMilliSeconds - this.state.previousMilliSeconds
      : 0;
    const interval = this.props.interval || 1000;

    // correct for small variations in actual timeout time
    const intervalSecondsRemaing = interval - (dt % interval);
    let timeout = intervalSecondsRemaing;

    if (intervalSecondsRemaing < interval / 2.0) {
      timeout += interval;
    }

    const milliSecondsRemaining = Math.max(
      this.state.milliSecondsRemaining - dt,
      0
    );
    const isComplete =
      this.state.previousMilliSeconds && milliSecondsRemaining <= 0;

    if (this.mounted && this.props.running) {
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId);
      }
      this.setState({
        timeoutId: isComplete ? null : setTimeout(this.tick, timeout),
        previousMilliSeconds: currentMilliSeconds,
        milliSecondsRemaining: milliSecondsRemaining,
      });
    }

    if (isComplete && this.props.running) {
      if (this.props.onTimeElapsed) {
        this.props.onTimeElapsed();
      }
      return;
    }

    if (this.props.onTick) {
      this.props.onTick(milliSecondsRemaining);
    }
  }

  getFormattedTime(milliseconds: number) {
    if (this.props.formatSecondsRemaining) {
      return this.props.formatSecondsRemaining(milliseconds);
    }

    const totalSeconds = Math.round(milliseconds / 1000);

    const seconds = totalSeconds % 60;
    const minutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalSeconds / 3600);

    const seconds_str = seconds < 10 ? "0" + seconds : seconds;
    const minutes_str = minutes < 10 ? "0" + minutes : minutes;
    let hours_str = hours < 10 ? "0" + hours : hours;

    hours_str = hours_str === "00" ? "" : hours_str + ":";

    return hours_str + minutes_str + ":" + seconds_str;
  }

  render() {
    return (
      <span style={this.props.style}>
        {this.getFormattedTime(this.state.milliSecondsRemaining)}
      </span>
    );
  }
}
