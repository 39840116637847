import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

/* 
Käytetyt muuttujat:
  - viewMode: kalenterin näkymä (day, week, month)
  - activeDate: aktiivinen päivämäärä kalenterissa 
  - activePage: aktiivisen sivun nimi (käytetään lähinnä staattisten sivujen kanssa) 
  - email: käyttäjän sähköpostiosoite
  - authCodeAccepted: onko käyttäjä hyväksynyt sähköpostiin lähetetyn koodin
  - agreementAccepted: onko käyttäjä hyväksynyt käyttöehdot
  - acceptedResearchType: käyttäjän valitsema tutkimustyyppi
  - wannaParticipate: haluaako käyttäjä osallistua tutkimukseen  
*/
export interface GlobalContextInterface {
  viewMode: string;
  activeDate: Date;
  email: string;
  authCodeAccepted: boolean;
  agreementAccepted: boolean;
  acceptedResearchType: number;
  wannaParticipate: boolean;
  activePage: string;
  logoutReason: string;
  themeDarknessPrefrence: string;
  setViewMode: (e: string) => void;
  setActiveDate: (e: Date) => void;
  setEmail: (e: string) => void;
  setAuthCodeAccepted: (e: boolean) => void;
  setAgreementAccepted: (e: boolean) => void;
  setAcceptedResearchType: (e: number) => void;
  setWannaParticipate: (e: boolean) => void;
  setActivePage: (e: string) => void;
  setLogoutReason: (e: string) => void;
  setThemeDarknessPrefrence: (e: string) => void;
}

type ContextProps = {
  children: React.ReactNode;
};

const GlobalContext = createContext<GlobalContextInterface>({
  viewMode: "",
  activeDate: new Date(),
  email: "",
  authCodeAccepted: false,
  agreementAccepted: false,
  acceptedResearchType: 0,
  wannaParticipate: false,
  activePage: "",
  logoutReason: "",
  themeDarknessPrefrence: "auto",
  setViewMode: (e: string) => {
    console.log(e); /*...*/
  },
  setActiveDate: (e: Date) => {
    console.log(e); /*...*/
  },
  setEmail: (e: string) => {
    console.log(e); /*...*/
  },
  setAuthCodeAccepted: (e: boolean) => {
    console.log(e); /*...*/
  },
  setAgreementAccepted: (e: boolean) => {
    console.log(e); /*...*/
  },
  setAcceptedResearchType: (e: number) => {
    console.log(e); /*...*/
  },
  setWannaParticipate: (e: boolean) => {
    console.log(e); /*...*/
  },
  setActivePage: (e: string) => {
    console.log(e); /*...*/
  },
  setLogoutReason: (e: string) => {
    console.log(e); /*...*/
  },
  setThemeDarknessPrefrence: (e: string) => {
    console.log(e);
  },
});

export const GlobalProvider: React.FunctionComponent<ContextProps> = ({
  children,
}) => {
  const [viewMode, setViewMode] = useState("day");
  const [activeDate, setActiveDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [authCodeAccepted, setAuthCodeAccepted] = useState<boolean>(false);
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);
  const [acceptedResearchType, setAcceptedResearchType] = useState<number>(0);
  const [wannaParticipate, setWannaParticipate] = useState<boolean>(false);
  const [activePage, setActivePage] = useState("");
  const [logoutReason, setLogoutReason] = useState("");
  const [themeDarknessPrefrence, setThemeDarknessPrefrence] = useState("auto");

  useEffect(() => {
    const email = localStorage.getItem("email");
    const authCodeAccepted = localStorage.getItem("authCodeAccepted");
    const agreementAccepted = localStorage.getItem("agreementAccepted");
    const acceptedResearchType = localStorage.getItem("acceptedResearchType");
    const wannaParticipate = localStorage.getItem("wannaParticipate");
    const themeDarknessPrefrence = localStorage.getItem(
      "themeDarknessPrefrence"
    );

    if (email) {
      setEmail(email);
    }

    if (authCodeAccepted) {
      setAuthCodeAccepted(authCodeAccepted === "true");
    }
    if (agreementAccepted) {
      setAgreementAccepted(agreementAccepted === "true");
    }
    if (acceptedResearchType) {
      setAcceptedResearchType(parseInt(acceptedResearchType));
    }
    if (wannaParticipate) {
      setWannaParticipate(wannaParticipate === "true");
    }

    if (themeDarknessPrefrence) {
      setThemeDarknessPrefrence(themeDarknessPrefrence);
    }
  }, []);

  const value = {
    viewMode,
    setViewMode,
    activeDate,
    setActiveDate,
    email,
    setEmail,
    authCodeAccepted,
    setAuthCodeAccepted,
    agreementAccepted,
    setAgreementAccepted,
    acceptedResearchType,
    setAcceptedResearchType,
    wannaParticipate,
    setWannaParticipate,
    activePage,
    setActivePage,
    logoutReason,
    setLogoutReason,
    themeDarknessPrefrence,
    setThemeDarknessPrefrence,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

/** Add PropTypes to prevent ESLint-errors */
GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalContext;
