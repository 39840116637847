import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { isUsingInstalledApp } from "./detect";

const firebaseConfig = {
    apiKey: "AIzaSyBXe-7TawTB4oOYY24TVjvE6JDsyPrVpHs",
    appId: "1:250289422516:ios:f322cf7ed85cde57", //This is iOS app id, needs to be changed
    projectId: "myflavoria",
    messagingSenderId: "250289422516"
  };

const app = initializeApp(firebaseConfig);

export function isInstalledApp() : boolean {
    return isUsingInstalledApp();
}

export async function setupPushNotifications() : Promise<string> {
    try {
        const messaging = getMessaging(app); // This fails in Firefox Private Browsing, throws Exception
        return await getToken(messaging);
    } catch (e : unknown) {
        return ""
    }
}

export function platformType() : string {
    // Use Capacitor.getPlatform in capacitor
    return "pwa";
}