import React, { useContext, useEffect, useState } from "react";
import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonText,
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonProgressBar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import BackendFactory from "../API/BackendFactory";
import { LunchListObject } from "../API/lib/JsonUtils";
import { Recipe, LunchItem } from "../API/lib/DataTypes";
import { subDays, format } from "date-fns";
import GlobalContext from "../GlobalContext";
import { logout_sessionexpired } from "../logout";
import Debouncer from "../components/DebouncerUtility";
import FlavoriaPage from "../components/FlavoriaPage";
import { informationCircleOutline } from "ionicons/icons";
import i18next from "../i18n";
import ErrorCard from "../components/ErrorCard";
import getFlavoriaLang from "../utils/flavoriaLang";

const debouncer = new Debouncer();

const SHOW_ADDITIONAL_INFO = false;

const Lunchlist: React.FC = () => {
  const [expandedState, setExpandedState] = useState<boolean[]>([false]); // Initial state for the cards
  const [ingredientsState, setIngredientsState] = useState("");
  const toggleCard = (index: number) => {
    const newExpandedState = [...expandedState];
    newExpandedState[index] = !newExpandedState[index];
    setExpandedState(newExpandedState);
  };
  const day = ["fri", "mon", "tue", "wed", "thu", "fri", "fri"];
  const now = new Date();
  const currentDay: string = day[now.getDay()];
  const BE = BackendFactory.getBackend();

  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [currentJson, setCurrentJson] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const [selectedDay, setSelectedDay] = useState(currentDay);

  useEffect(() => {
    if (loading || !SHOW_ADDITIONAL_INFO) return;
    const fetchData = async () => {
      try {
        const date = getDateByWeekday(selectedDay);
        const response = await fetch(
          "https://www.sodexo.fi/ruokalistat/output/daily_json/102/" + date
        );
        const responseJson = await response.json();
        setIngredientsState(JSON.stringify(responseJson.courses));
      } catch (error) {
        console.log("Error fetching sodexo api data:", error);
      }
    };
    fetchData();
  }, [loading]);

  function getDateByWeekday(weekday: string) {
    const day_lookup = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let day = now.getDay();
    if (day == 0) day = 7; // Use 7 as sunday to use the previous week (the lunchdata is updated on Monday)
    const delta = day_lookup.indexOf(weekday) - day;
    return format(subDays(now, -delta), "yyyy-MM-dd");
  }

  function renderLunchList() {
    if (loading) {
      return (
        <div style={{ textAlign: "start", margin: "10px", maxWidth: "1000px" }}>
          <IonSkeletonText
            animated={true}
            style={{ margin: "10px", width: "50%" }}
          />
          <IonSkeletonText
            animated={true}
            style={{ margin: "10px", width: "70%" }}
          />
          <IonSkeletonText
            animated={true}
            style={{ margin: "10px", width: "70%" }}
          />
        </div>
      );
    } else if (errorCode != ""){
      return (<ErrorCard errorCode={errorCode}/>)
    } else {
      try {
        const lang = getFlavoriaLang(i18next.language);
        const ll = new LunchListObject(currentJson);
        const titles: Array<LunchItem> = ll.getLunchItems(lang);
        const renderedLunchList = titles.map((item: LunchItem) => (
          <IonCard key={item.key}>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonCardHeader>
                    <IonCardTitle>
                      {item.title}{" "}
                      {item.properties.map((prop, idx) => (
                        <IonBadge
                          style={{ marginLeft: "3px", marginRight: "3px" }}
                          key={idx}
                        >
                          {prop}
                        </IonBadge>
                      ))}
                    </IonCardTitle>
                    <IonCardSubtitle>{item.shortDesc}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>{item.prices}</IonCardContent>
                </IonCol>
                <IonCol size="2" className="ion-text-end">
                  {
                    <IonButton
                      onClick={() => toggleCard(item.key)}
                      style={{
                        marginTop: "15px",
                        display: SHOW_ADDITIONAL_INFO ? "inline-block" : "none",
                      }}
                    >
                      <IonIcon icon={informationCircleOutline}></IonIcon>
                    </IonButton>
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
            {}
            {expandedState[item.key] ? (
              <IonGrid
                style={{ marginTop: "-20px" }}
                id={"ingredients" + item.key}
              >
                {getIngredientElements(item.key + 1)}
              </IonGrid>
            ) : null}
          </IonCard>
        ));

        return (
          <div
            style={{ textAlign: "start", margin: "10px", maxWidth: "1000px" }}
          >
            {renderedLunchList}
          </div>
        );
      } catch (e) {
        console.log(e);
        return (
          <IonCard>
            <IonCardTitle>
              <h2>{t("No lunch list available yet for this day.")}</h2>
            </IonCardTitle>
          </IonCard>
        );
      }
    }
  }

  function getIngredientElements(objectKey: number) {
    const courseInfo = JSON.parse(ingredientsState);
    const ingredientsInfo = courseInfo[objectKey]["recipes"];

    let ingredientElements: JSX.Element[] = [];

    for (const ingredientsInfoKey in ingredientsInfo) {
      const num = Number(ingredientsInfoKey);
      const isNumber = !isNaN(num) && num >= 0 && Number.isInteger(num);
      if (!isNumber) continue;
      ingredientElements = ingredientElements.concat(
        getRecipeIngredientsInfo(
          ingredientsInfo[ingredientsInfoKey],
          ingredientsInfoKey
        )
      );
    }

    return ingredientElements;
  }

  function getRecipeIngredientsInfo(recipe: Recipe, elementKey: string) {
    const ingredients = recipe["ingredients"];
    const recipeName = recipe["name"];

    let start = 0;
    let hasFirstBracketStarted = false;
    let hasFirstBracketEnded = false;
    let hasThirdBracketStarted = false;
    let hasThirdBracketEnded = false;
    let lastCharacter = "";

    const rows = [];
    let row = "";

    //this loop will parse through each character of the ingredients string and add rows to the rows array
    for (let current = 0; current < ingredients.length; current++) {
      if (ingredients[current] == "[") hasThirdBracketStarted = true;
      else if (ingredients[current] == "]") hasThirdBracketEnded = true;
      else if (ingredients[current] == "(") hasFirstBracketStarted = true;
      else if (ingredients[current] == ")") {
        hasFirstBracketEnded = true;
        // checking if the percentage is inside the square bracket, because those will not be considered
        if (hasThirdBracketStarted && !hasFirstBracketEnded) {
          // re-initialize the parenthesis flag values
          hasFirstBracketStarted = false;
          hasFirstBracketEnded = false;
        }
        // checking if the percentage is outside the square bracket, because those will be considered
        else if (
          hasFirstBracketStarted &&
          lastCharacter == "%" &&
          (hasThirdBracketEnded ||
            (!hasThirdBracketStarted && !hasThirdBracketEnded))
        ) {
          row = ingredients.substring(start, current + 1);
          if (row.charAt(0) == ",") {
            row = row.substring(1);
          }
          rows.push(row);
          // re-initialize the flag values
          start = current + 1;
          hasFirstBracketStarted = false;
          hasFirstBracketEnded = false;
          hasThirdBracketStarted = false;
          hasThirdBracketEnded = false;
        }
      }
      lastCharacter = ingredients[current];
    }

    const ingredientElements = [];
    let key = 1;
    // creating the element from each row
    for (const rowString of rows) {
      const ingredientPercentageStartsAt = rowString.lastIndexOf("(");
      let ingredientDescription = rowString.substring(
        0,
        ingredientPercentageStartsAt
      );
      ingredientDescription = ingredientDescription.trim();
      let ingredientPercentage = rowString.substring(
        ingredientPercentageStartsAt
      );
      ingredientPercentage = ingredientPercentage.trim();
      ingredientPercentage = ingredientPercentage.replace(/[()\s]/g, "");

      ingredientElements.push(
        <IonRow key={key}>
          <IonCol size="10">
            <IonText>{ingredientDescription}</IonText>
          </IonCol>
        </IonRow>
      );

      ingredientElements.push(
        <IonRow key={key * 20}>
          <IonCol size="10">
            <IonProgressBar
              value={35}
              color="success"
              style={{
                height: "0.5em",
                marginTop: ".5em",
                width: ingredientPercentage.replace(",", "."),
              }}
            />
          </IonCol>
          <IonCol size="2">
            <IonText>{ingredientPercentage}</IonText>
          </IonCol>
        </IonRow>
      );
      key++;
    }

    return (
      <IonCard key={elementKey}>
        <IonCardHeader color="secondary" mode="ios" translucent={true}>
          <IonCardSubtitle>{recipeName}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>{ingredientElements}</IonCardContent>
      </IonCard>
    );
  }

  debouncer.set_debouncer(() => {
    BE.getLunchList(getDateByWeekday(selectedDay))
      .then((result) => {
        setCurrentJson(result);
        setLoading(false);
      })
      .catch((reason) => {
        if (reason == "Expired Token") {
          logout_sessionexpired(globalContext, t);
        } else {
          if(typeof reason == "string")
            setErrorCode(reason)
          else
            setErrorCode("UnexpectedError");

          setLoading(false);
        }
      });
  });

  if (loading) {
    debouncer.call();
  }

  return (
    <FlavoriaPage pageID={"ACTIVE_PAGE_LUNCH_LIST"}>
      <IonSegment
        value={selectedDay}
        onIonChange={(e) => {
          setSelectedDay(e.target.value?.toString() || currentDay);
          setLoading(true);
        }}
      >
        <IonSegmentButton value={"mon"} style={{ minWidth: "initial" }}>
          <IonLabel>{t("Mon")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={"tue"} style={{ minWidth: "initial" }}>
          <IonLabel>{t("Tue")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={"wed"} style={{ minWidth: "initial" }}>
          <IonLabel>{t("Wed")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={"thu"} style={{ minWidth: "initial" }}>
          <IonLabel>{t("Thu")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={"fri"} style={{ minWidth: "initial" }}>
          <IonLabel>{t("Fri")}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <br />
      <center>
        {renderLunchList()}
        <div
          style={{
            textAlign: "start",
            lineHeight: "1.8em",
            maxWidth: "1000px",
          }}
        >
          <IonCard>
            <IonCardContent>
              <IonBadge>G</IonBadge> <IonText>{t("Gluten-free")}</IonText>
              <br />
              <IonBadge>M</IonBadge> <IonText>{t("Free of Milk")}</IonText>
              <br />
              <IonBadge>L</IonBadge> <IonText>{t("Lactose-free")}</IonText>
              <br />
              <IonBadge>VL</IonBadge> <IonText>{t("Low in Lactose")}</IonText>
              <br />
              <IonBadge>V</IonBadge> <IonText>{t("Vegan")}</IonText>
              <br />
              <br />
              <IonText>
                {t(
                  "Changes in lunch lists and descriptions are possible. If you have any nutrition allergy-related questions, staff is pleased to help you with them."
                )}
              </IonText>
            </IonCardContent>
          </IonCard>
        </div>
      </center>
    </FlavoriaPage>
  );
};

export default Lunchlist;
