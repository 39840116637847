import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonModal,
  IonContent,
  IonChip,
  IonLabel,
  IonList,
  IonItem,
  IonSkeletonText,
  IonIcon,
  IonFooter,
  IonItemGroup,
  IonItemDivider,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import BackendFactory from "../API/BackendFactory";
import { ExtraTemplateObject } from "../API/lib/JsonUtils";
import loadOnlyOnce from "../utils/loadonlyonce";
import {
  ExtraTemplateItem,
  MealItemData,
  TakenExtraItem,
  getLocalizedString,
} from "../API/lib/DataTypes";
import i18n from "../i18n";
import { addCircle, closeCircle, informationCircle, removeCircle } from "ionicons/icons";
import CountBadge from "../components/CountBadge";

type CallbackFunction = () => void;

interface Props {
  trigger?: string;
  isOpen?: boolean;
  onDidDismiss?: CallbackFunction;
  onDidUpdate?: CallbackFunction;
  mealItems?: Array<MealItemData>;
  sessionId: string;
}
const BE = BackendFactory.getBackend();
const loadTemplate = () => {
  return BE.getMealExtrasTemplate();
};

const mealExtrasDialog: React.FC<Props> = (props: Props) => {
  const mealsModal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [mealsExtraArray, setMealExtrasArray] = useState<Array<TakenExtraItem>>(
    []
  );
  const [mealsExtraTemplate, setMealExtrasTemplate] = useState<string>("");

  useEffect(() => {
    loadOnlyOnce(loadTemplate).then((result) => {
      setMealExtrasTemplate(result);
    });
  });

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  function handleDismiss() {
    if (props.onDidDismiss != null) props.onDidDismiss();

    if (changesMade && props.onDidUpdate) {
      props.onDidUpdate();
    }

    setChangesMade(false);
    setMealExtrasArray([]);
  }

  async function canDismiss(data?: never, role?: string) {
    return (role !== "gesture" && role !== "backdrop") || !changesMade;
  }

  function handlePresent() {
    const extra_id_dict: Record<string, ExtraTemplateItem> = {};
    (
      new ExtraTemplateObject(
        mealsExtraTemplate
      ).getExtraTemplate() as Array<ExtraTemplateItem>
    ).map((extra) => {
      extra_id_dict[extra.id] = extra;
    });
    setChangesMade(false);
    if (props.mealItems != null) {
      const currentItems = props.mealItems.map((value) => {
        if (value.id in extra_id_dict && value.selectedQuantity != null && value.selectedQuantity != 0 ) {
          const takenItem = extra_id_dict[value.id] as TakenExtraItem;
          takenItem.quantity = value.selectedQuantity;
          return takenItem;
        } else return null;
      });

      setMealExtrasArray(currentItems.filter(notEmpty));
    }
  }

  function extrasToJson() {
    interface ItemQTYpair {
      id: string;
      value: number;
    }

    const extrasJson = {
      extras: {
        bread: new Array<ItemQTYpair>(),
        butter: new Array<ItemQTYpair>(),
        dressing: new Array<ItemQTYpair>(),
        drink: new Array<ItemQTYpair>(),
      },
      id: props.sessionId,
    };

    const extras = new ExtraTemplateObject(
      mealsExtraTemplate
    ).getExtraTemplate() as Array<ExtraTemplateItem>;

    extras.map((item) => {
      switch (item.category) {
        case "bread":
          extrasJson.extras.bread.push({
            id: item.id,
            value: 0,
          });
          break;
        case "butter":
          extrasJson.extras.butter.push({
            id: item.id,
            value: 0,
          });
          break;
        case "drink":
          extrasJson.extras.drink.push({
            id: item.id,
            value: 0,
          });
          break;
        case "dressing":
          extrasJson.extras.dressing.push({
            id: item.id,
            value: 0,
          });
          break;
      }
    });

    const incrementIfExists = (
      array: Array<ItemQTYpair>,
      item: TakenExtraItem,
      qty: number
    ) => {
      const result = array.findIndex((value) => value.id == item.id);
      if (result >= 0) array[result].value += qty;
    };

    mealsExtraArray.map((item) => {
      switch (item.category) {
        case "bread":
          incrementIfExists(extrasJson.extras.bread, item, item.quantity);
          break;
        case "butter":
          incrementIfExists(extrasJson.extras.butter, item, item.quantity);
          break;
        case "drink":
          incrementIfExists(extrasJson.extras.drink, item, item.quantity);
          break;
        case "dressing":
          incrementIfExists(extrasJson.extras.dressing, item, item.quantity);
          break;
      }
    });

    return JSON.stringify(extrasJson);
  }

  function saveChanges() {
    BE.updateMealsSide(props.sessionId, extrasToJson());
  }

  function addMeal(extra: ExtraTemplateItem) {
    const arr = [...mealsExtraArray];
    const alreadyExistingMeal = arr.find((meal) => meal.id == extra.id);
    if (alreadyExistingMeal) {
      alreadyExistingMeal.quantity++;
    } else {
      const nuItem = extra as TakenExtraItem;
      nuItem.quantity = 1;
      arr.push(nuItem);
    }
    setMealExtrasArray(arr);
    setChangesMade(true);
  }

  function subMeal(extra: ExtraTemplateItem) {
    const arr = [...mealsExtraArray];
    const alreadyExistingMeal = arr.find((meal) => meal.id == extra.id);
    if (alreadyExistingMeal) {
      alreadyExistingMeal.quantity--;

      if (alreadyExistingMeal.quantity < 1) {
        const temp = mealsExtraArray.slice();
        temp.splice(arr.indexOf(alreadyExistingMeal), 1);
        setMealExtrasArray(temp);
        setChangesMade(true);
        
        return;
      }

      setMealExtrasArray(arr);
      setChangesMade(true);
    }
  }

  function mapMealCategory(mapMealCategory: Array<ExtraTemplateItem>) {
    const getQty = function (arr : Array<TakenExtraItem>, extraId : string) : number {
      const result = arr.filter((meal) => meal.id == extraId)
      return result.length ? result[0].quantity : 0;
    }

    const renderItemContents = function (extra : ExtraTemplateItem) {
      const qty = getQty(mealsExtraArray, extra.id)
      return (
        <>
        {getLocalizedString(extra.name, i18n.language) + ", " + getLocalizedString(extra.serving.description, i18n.language)}
        {qty > 0 ? (<IonButton color="danger" onClick={(e) => {subMeal(extra);e.stopPropagation()}} slot="start"><IonIcon slot="start" src={removeCircle}/>{t("Remove 1x")}</IonButton>) : ""}
        {qty > 0 ? (<CountBadge qty={qty} slot="end" />) : ( "" )}
        </>
      )
    }

    return mapMealCategory.map((extra, index) => (
      <IonItem
        key={"extra-" + index}
        color={getQty(mealsExtraArray, extra.id) ? "light" : ""}
        button={true}
        detail={true}
        detailIcon={addCircle}
        onClick={() => addMeal(extra)}
      >
        {
          renderItemContents(extra)
        }

      </IonItem>
    ));
  }

  function renderMealExtraSelection(mealTemplate: string) {
    try {
      const all_extras = new ExtraTemplateObject(
        mealTemplate
      ).getExtraTemplate() as Array<ExtraTemplateItem>;
      const drinks = all_extras.filter((meal) => meal.category == "drink");
      const breads = all_extras.filter((meal) => meal.category == "bread");
      const spreads = all_extras.filter((meal) => meal.category == "butter");
      const dressing = all_extras.filter((meal) => meal.category == "dressing");

      return (
        <>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{t("Drinks")}</IonLabel>
            </IonItemDivider>
            {mapMealCategory(drinks)}
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{t("Breads")}</IonLabel>
            </IonItemDivider>
            {mapMealCategory(breads)}
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{t("Spreads")}</IonLabel>
            </IonItemDivider>
            {mapMealCategory(spreads)}
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{t("Dressing")}</IonLabel>
            </IonItemDivider>
            {mapMealCategory(dressing)}
          </IonItemGroup>
        </>
      );
    } catch (err) {
      return (
        <IonItem>
          <IonSkeletonText></IonSkeletonText>
        </IonItem>
      );
    }
  }

  function renderChips(meals: Array<TakenExtraItem>) {
    if (meals.length == 0) {
      return <IonChip disabled={true}>{t("No Meal Extras")}</IonChip>;
    }

    return (
      <>
        {meals.map((meal, index) => {
          return (
            <IonChip
              class="chipHighlight"
              onClick={() => {
                const temp = mealsExtraArray.slice();
                temp.splice(index, 1);
                setMealExtrasArray(temp);
                setChangesMade(true);
              }}
              key={"meal-extra-" + index}
            >
              {meal.quantity > 1 ? (
                <>
                  <CountBadge qty={meal.quantity} />
                  <span style={{ padding: "3px" }} />
                </>
              ) : (
                ""
              )}
              {getLocalizedString(meal.name, i18n.language)}
              <IonIcon icon={closeCircle}></IonIcon>
            </IonChip>
          );
        })}
      </>
    );
  }

  return (
    <IonModal
      ref={mealsModal}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      trigger={props.trigger}
      isOpen={props.isOpen}
      onDidPresent={() => handlePresent()}
      onDidDismiss={() => handleDismiss()}
      canDismiss={canDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("Meal Extras")}</IonTitle>

          <IonButtons
            style={{ display: changesMade ? "initial" : "none" }}
            slot="start"
          >
            <IonButton
              color={"danger"}
              onClick={() => {
                setChangesMade(false);
                mealsModal.current?.dismiss();
              }}
            >
              {t("Discard")}
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton
              color={changesMade ? "success" : "primary"}
              onClick={() => {
                saveChanges();
                mealsModal.current?.dismiss();
              }}
            >
              {changesMade ? t("Save") : t("Close")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem>
          <IonIcon slot="start" size="large" icon={informationCircle}></IonIcon>
          <IonLabel>
            {t(
              "Tap items to add them to extras. At the bottom of the screen you can see the items you have listed. Tap the save-button when you are done to save all the logs or discard-button to close without saving. If you have not made any chages you can close, just by tapping the close-button at the top-right corner."
            )}
          </IonLabel>
        </IonItem>
        <IonList>{renderMealExtraSelection(mealsExtraTemplate)}</IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>{renderChips(mealsExtraArray)}</IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default mealExtrasDialog;
