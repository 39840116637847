/* eslint @typescript-eslint/no-var-requires: "off" */

import AbstractBackend from "./AbstractBackend";

const meals = require("./debugmeals.json");
const DebugExtras = require("./Debug-extras-template.json");

class DebugBackend implements AbstractBackend {
  sendCodeToEmail(email: string): Promise<void> {
    console.log('Sent email "' + email + '" to server!');
    return new Promise((resolve) => {
      resolve();
    });
  }

  sendVerifyCode(code: string): Promise<void> {
    console.log('Sent Verify Code "' + code + '" to server!');
    return new Promise((resolve, reject) => {
      if (code !== "1337") {
        reject("Incorrect code. Code must be 1337.");
      } else {
        resolve();
      }
    });
  }

  getVersionInfo(): Promise<string> {
    console.log("Requested User Info");
    return new Promise((resolve) => {
      resolve(
        '{}'
      );
    });
  }

  fetchTermsOfUse(): Promise<string> {
    return new Promise((resolve) => {
      resolve("Terms of Use. Cthulhu fththoanbt.");
    });
  }

  acceptUserAgreement(researchType: number): Promise<void> {
    console.log("Accepted with researchtype: " + researchType);
    return new Promise((resolve) => {
      resolve();
    });
  }

  getStaticContent(page: string): Promise<string> {
    console.log("Tried to Fetch Static Content: " + page);
    return new Promise((resolve) => {
      resolve("<p>Sample Text</p>");
    });
  }

  getLunchList(date: string): Promise<string> {
    return new Promise((resolve) => {
      console.log(
        "Tried to Fetch Lunch Menu for Day: " + date + ". Returning template."
      );
      setTimeout(() => {
        resolve(
          '[{"venue":"flavoria","day":"2023-10-06","courses":[{"en":{"title":"Pesto pasta"},"fi":{"desc":"FROM THE FIELD-VEGAN","title":"Pestopastaa"},"price":{"other":"8,80","student":"2,95"},"properties":["M"]},{"en":{"title":"Cajun chicken in bell pepper sauce with roasted sweet potato and potato"},"fi":{"desc":"FROM OUR FAVORITES 2","title":"Cajun-broileria paprikakastikkeessa, paahdettua bataattia ja perunaa"},"price":{"other":"8,80","student":"2,95"},"properties":["G","M"]},{"en":{"title":"Minced lamb meat patty, garlic potatos and mint yogurt"},"fi":{"desc":"FROM OUR FAVORITES 1","title":"Lammasjauhelihapihvi, valkosipuliperunoita ja minttujugurttia"},"price":{"other":"8,80","student":"4,95"},"properties":["L"]},{"en":{"title":"Baguette of the day (Chicken)"},"fi":{"desc":"FROM OUR BAKERY (kahvilasta)","title":"Päivän patonki (Broileri)"},"price":{"other":"7,80","student":"2,95"},"properties":["M"]},{"en":{"title":"Pizza Americano (ham-pineapple-blue cheese)"},"fi":{"desc":"FROM THE KITCHEN (grillistä)","title":"Pizza Americano (kinkku-ananas-aura)"},"price":{"other":"10,40","student":"5,60"},"properties":["L"]},{"en":{"title":"Pizza Vegetarian (champignon-bell pepper-blue cheese)"},"fi":{"desc":"FROM THE KITCHEN VEGGIE (grillistä)","title":"Pizza Vegetarian (herkkusieni-paprika-Aura)"},"price":{"other":"10,40","student":"5,60"},"properties":["L"]}]}]'
        );
      }, 500);
    });
  }

  getNewsFeed(): Promise<string> {
    console.log("Requested News Feed");
    return new Promise((resolve) => {
      resolve(
        '{"title":"Debug News","items":[{"title":"New Version of myFlavoria is being developed","link":"https://gitlab-ext.utu.fi/flavoria/myflavoria.fi-frontend/","text":"","dc:creator":"Flavoria-tiimi","pubdate":"Thu, 05 Oct 2023 05:38:54 +0000","category":["Ajankohtaista","Etusivu, uutiset"],"guid":{"ispermalink":"false","text":"https://gitlab-ext.utu.fi/flavoria/myflavoria.fi-frontend"},"description":"<p>A completely new version of myFlavoria-app is expected to be released soon.</p>","content:encoded":"","plaintext":"A completely new version of myFlavoria-app is expected to be released soon."}]}'
      );
    });
  }

  getCurrentResearches(): Promise<string> {
    console.log("Requested Current Researches");
    return new Promise((resolve) => {
      resolve(
        '[{"id": 60,"createdAt": "2019-05-29T12:24:35.028+00:00","creator": "System Administrator","modifiedAt": "2020-09-08T06:18:28.004+00:00","modifier": null,"archivedAt": null,"archiver": null,"title": "myFlavoria Development","description": "You can develop myFlavoria-app here.","uri": "https://gitlab-ext.utu.fi/flavoria/myflavoria.fi-frontend","lang": "en-US","type": "opensurvey","shareable": false,"start": null,"end": null,"contact": {"name": "Flavoria-tiimi","email": "flavoria@utu.fi","phone": null},"status": "new"}]'
      );
    });
  }

  getEarlierResearches(): Promise<string> {
    console.log("Requested Earlier Researches");
    return new Promise((resolve) => {
      resolve(
        '[{"id": 10,"createdAt": "2019-05-29T12:24:35.028+00:00","creator": "System Administrator","modifiedAt": "2020-09-08T06:18:28.004+00:00","modifier": null,"archivedAt": null,"archiver": null,"title": "Flavorian avoin virheraportti","description": "Voit lähettää alla olevan tutkimusnapin kautta virheraportteja.","uri": "https://redcap.utu.fi/surveys/?s=DJ8EWJPLPAX7LAJP&flavoriaid=2813","lang": "fi","type": "opensurvey","shareable": false,"start": null,"end": null,"contact": {"name": "Flavoria-tiimi","email": "flavoria@utu.fi","phone": null},"status": "seen"}]'
      );
    });
  }

  markResearchAsSeen(id: number): Promise<void> {
    console.log(`Marked research ${id} as seen.`);
    return new Promise((resolve) => resolve());
  }

  getCoupons(): Promise<string> {
    console.log("Requested Coupons");
    return new Promise((resolve) => {
      resolve(
        '[{"id": 1337, "createdAt": "2019-04-05T10:13:20.124+00:00","creator": "System Administrator", "modifiedAt": "2019-05-06T13:16:54.367+00:00","modifier": null, "archivedAt": null, "archiver": null,"title": "Debug Test Coupon","description": "This is a debug test coupon for the debug backend.","summary": "Test Coupon","type": "coupon","i18n": {"en": {"title": "","summary": null,"description": null}},"start": null,"end": null,"issued": "2023-08-30T12:50:39.450+00:00","used": null,"expires": "2023-12-01T00:00:00.000+00:00"}, {"id": 1337, "createdAt": "2019-04-05T10:13:20.124+00:00","creator": "System Administrator", "modifiedAt": "2019-05-06T13:16:54.367+00:00","modifier": null, "archivedAt": null, "archiver": null,"title": "Debug Test Coupon","description": "This is a debug test coupon for the debug backend.","summary": "Test Coupon","type": "coupon","i18n": {"en": {"title": "","summary": null,"description": null}},"start": null,"end": null,"issued": "2023-08-30T12:50:39.450+00:00","used": null,"expires": "2023-12-01T00:00:00.000+00:00"}, {"id": 34, "createdAt": "2019-04-05T10:13:20.124+00:00","creator": "System Administrator", "modifiedAt": "2019-05-06T13:16:54.367+00:00","modifier": null, "archivedAt": null, "archiver": null,"title": "Debug Test Coupon #2","description": "This is a debug test coupon for the debug backend. This one has a different ID.","summary": "Test Coupon #2","type": "coupon","i18n": {"en": {"title": "","summary": null,"description": null}},"start": null,"end": null,"issued": "2023-08-30T12:50:39.450+00:00","used": null,"expires": "2023-12-01T00:00:00.000+00:00"}]'
      );
    });
  }
  getAds(): Promise<string> {
    console.log("Requested Ads");
    return new Promise((resolve) => {
      resolve("[]");
    });
  }

  getCampaign(): Promise<string> {
    console.log("Requested Campaingns");
    return new Promise((resolve) => {
      resolve("[]");
    });
  }

  getUserInfo(): Promise<string> {
    console.log("Requested User Info");
    return new Promise((resolve) => {
      resolve(
        '{ "id": 1337, "qr": "13371337", "name": null, "email": "debug@flavoria.fi","termsVersion": 1, "roles": []}'
      );
    });
  }

  useCoupon(id: string): Promise<void> {
    console.log("Used coupon with couponId: " + id);
    return new Promise((resolve) => {
      resolve();
    });
  }

  getMealsDuringTimeperiod(start: Date, end: Date): Promise<string> {
    console.log(
      "Fetch meals during timeperiod: " +
        start.toDateString() +
        " and " +
        end.toDateString()
    );
    if (start.getMonth() === 8 && start.getFullYear() == 2023)
      return new Promise((resolve) => {
        resolve(JSON.stringify(meals));
      });
    else
      return new Promise((resolve) => {
        resolve("[]");
      });
  }

  getMealExtrasTemplate(): Promise<string> {
    return new Promise((resolve) => {
      resolve(JSON.stringify(DebugExtras));
    });
  }

  updateMealsSide(session_id: string, mealJSON: string): Promise<void> {
    console.log(
      "Updated meals with session id: " +
        session_id +
        "and updated values were:" +
        mealJSON
    );
    return new Promise((resolve) => {
      resolve();
    });
  }
}

export default DebugBackend;
