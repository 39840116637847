import React, { useState } from "react";
import { IonContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import TemplateCard from "../components/TemplateCard";
import BackendFactory from "../API/BackendFactory";
import Debouncer from "../components/DebouncerUtility";
import ErrorCard from "../components/ErrorCard";

interface Props {
  pageName: string;
}

const debouncer = new Debouncer();
const StaticPage: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [staticContent, setStaticContent] = useState<string>("");
  const [errorCode, setErrorCode] = useState("");
  const [pageNameOfStaticContent, setPageNameOfStaticContent] =
    useState<string>("");

  debouncer.set_debouncer(() => {
    if (props.pageName != pageNameOfStaticContent) {
      BackendFactory.getBackend()
        .getStaticContent(props.pageName)
        .then((s: string) => {
          setStaticContent(s);
          setPageNameOfStaticContent(props.pageName);
        })
        .catch((reason) => {
            if(typeof reason == "string")
              setErrorCode(reason)
            else
              setErrorCode("UnexpectedError");
  
              setStaticContent(
                `<h1>${t("Could not load html-page: ")}"${props.pageName}"</h1>`
              );
          }
        );
    }
  });

  debouncer.call();

  function renderStaticContent() {
    if (staticContent == "") {
      return <TemplateCard />;
    }  else if (errorCode != ""){
      return (<ErrorCard errorCode={errorCode}/>)
    } else {
      return (
        <div
          className="StaticContent"
          dangerouslySetInnerHTML={{ __html: staticContent }}
        />
      );
    }
  }
  return <IonContent>{renderStaticContent()}</IonContent>;
};

export default StaticPage;
