type DaemonCallback = (self: BackgroundService) => Promise<unknown>;

class BackgroundService {
  timer: number;
  callbacks: Array<DaemonCallback>;
  jsTimerID: NodeJS.Timeout | null;
  runAtLeastOnce: boolean;

  constructor(initialCallback: DaemonCallback) {
    this.timer = 0;
    this.callbacks = [initialCallback];
    this.jsTimerID = null;
    this.runAtLeastOnce = false;
  }

  async runCallbacks() {
    this.runAtLeastOnce = true;
    for (let i = 0; i < this.callbacks.length; i++) {
      await this.callbacks[i](this);
    }
  }

  setupTimer() {
    if (!this.timer) return;

    if (!this.callbacks.length) return;

    if (this.jsTimerID) clearInterval(this.jsTimerID);

    this.jsTimerID = setInterval(() => {
      this.runCallbacks();
    }, this.timer);
  }

  setFrequency(ms: number) {
    this.timer = ms;

    if (this.callbacks.length) this.setupTimer();
  }

  addCallback(callback: DaemonCallback) {
    this.callbacks.push(callback);

    if (this.runAtLeastOnce) callback(this);
    else this.runCallbacks();

    if (this.timer && !this.jsTimerID) this.setupTimer();
  }
}

export default BackgroundService;
