import React from "react";
import licenseInfo from "../licenses.json"
import buildInfo from "../../package.json"
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";

//<p>Used Libraries: {Object.keys(appInfo.dependencies).map((lib) => {return (<span key={lib}>{lib+ ", "}npm</span>)})}</p>

const SectionTitle: React.FC = () => {
    const { t } = useTranslation();

  return (<>
    <center><img src={process.env.PUBLIC_URL + "/logo192.png"}/></center> 
    <h1>My Flavoria®</h1>
    <p>{t("Version: ")}{buildInfo.version}</p>
    <p>{t("Used Third Party Software Components:")}</p>
    <IonAccordionGroup>
        {
            Object.entries(licenseInfo["dependencies-info"]).map(([name, value]) => {
                    return (
                        <IonAccordion key={name} value={name}>
                        <IonItem slot="header" color="light">
                        <IonLabel>{value["full-name"]+" – "+t("License: ")+value["license-type"]}</IonLabel>
                        </IonItem>
                        <div className="ion-padding" slot="content">
                          <pre className="force-text-wrap">
                        {value["license"]}
                          </pre>
                        </div>
                    </IonAccordion>
                    )
            })
        }
    </IonAccordionGroup>
    </>
  );
};

export default SectionTitle;
