import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    errorCode: string;
  }

const ErrorCode: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();

    if (props.errorCode == "Timeout" || props.errorCode == "Offline") {
        return (
          <IonCard>
            <IonCardHeader>
            <IonItem lines="none">
              <IonIcon slot="start" icon={alertCircleOutline}></IonIcon>
                <IonCardTitle>{t("Could not connect to My Flavoria server")}</IonCardTitle>
              </IonItem>
            </IonCardHeader>
            
            <IonCardContent>
              <IonItem lines="none">
                <IonLabel>{t("Please check your internet connections and ensure your local network has not restricted your acceess to the internet.")}</IonLabel>
              </IonItem>
            </IonCardContent>
          </IonCard>
        );
      } else {
        return (
        <IonCard>
            <IonCardHeader>
            <IonItem lines="none">
              <IonIcon slot="start" icon={alertCircleOutline}></IonIcon>
                <IonCardTitle>{t("Something went wrong...")}</IonCardTitle>
              </IonItem>
            </IonCardHeader>
            
            <IonCardContent>
              <IonItem lines="none">
                <IonLabel>{t("Most likely some of our services is currently not working.")}</IonLabel>
              </IonItem>
            </IonCardContent>
          </IonCard>
        );
      }
};

export default ErrorCode;
