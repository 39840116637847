import React from "react";
import { IonNote } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PersonalQR from "../components/PersonalQrCode";
import FlavoriaPage from "../components/FlavoriaPage";
import MealLogged from "../components/MealLogged";
import useNewMeals from "../utils/useNewMeals";

const QR: React.FC = () => {
  const { t } = useTranslation();
  const newMeals = useNewMeals();

  return (
    <FlavoriaPage pageID={"ACTIVE_PAGE_QR"}>
      <br />
      <PersonalQR maxSize={512} />
      <center>
        <br />
        <IonNote>
          {t("Please show this QR-code to the reader next to the cash register.")}
        </IonNote>
      </center>

      <MealLogged
        mealId={newMeals ? newMeals : ""}
        showMealExtraPopup={newMeals != null}
      />
    </FlavoriaPage>
  );
};

export default QR;
