import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTitle,
  IonToolbar,
  setupIonicReact,
  IonList,
  IonItem,
  IonModal,
  IonNav,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import { useTranslation } from "react-i18next";
import {
  cafeOutline,
  compassOutline,
  logOutOutline,
  personCircleOutline,
  readerOutline,
  happyOutline,
  settingsOutline,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

// Ionic v8 dark theme support:
// import '@ionic/react/css/palettes/dark.always.css';
import "@ionic/react/css/palettes/dark.class.css";
// import '@ionic/react/css/palettes/dark.system.css';

/* Flavoria's Customized palettes */
import "./theme/Flavoria.scss";

import GlobalContext from "./GlobalContext";
import FlavoriaHeaderBar from "./components/FlavoriaHeaderBar";
import Lunchlist from "./pages/Lunchlist";
import Benefits from "./pages/Benefits";
import Research from "./pages/Research";
import Meals from "./pages/Meals";
import StaticPage from "./pages/StaticPage";
import LoginPage from "./pages/loginPages/LoginPage";
import { ResearchType } from "./pages/loginPages/ConsentFormPage";
import logout from "./logout";
import QR from "./pages/QR";
import MyFlavoriaText from "./assets/my_flavoria_text_white.png";
import { rootPath } from "./Utils";

import myFlavoriaIcon from "./assets/icon/svgicons/my.svg";
import benefitsIcon from "./assets/icon/svgicons/benefits.svg";
import restaurantIcon from "./assets/icon/svgicons/restaurant.svg";
import researchIcon from "./assets/icon/svgicons/research.svg";
import mealsIcon from "./assets/icon/svgicons/meals.svg";
import SettingsDialog from "./pages/SettingsDialog";
import getSelfID from "./utils/getSelfId";
import i18next from "./i18n";
import getFlavoriaLang from "./utils/flavoriaLang";
import { isInstalledApp } from "./platform/abstraction";
import { isUpToDate } from "./platform/versionCheck";
import buildInfo from "../package.json"
import SoftwareNotification from "./components/SoftwareNotification";


function getStylePrefrence() {
  const pref = localStorage.getItem("stylePrefrence");
  if (pref === "md" || pref === "ios") return pref;
  else return undefined;
}

setupIonicReact({ mode: getStylePrefrence() });

export function App() {
  const userContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);
  const [modal_open, set_modal_open] = useState(false);
  const [static_content, set_static_content] = useState("");
  const [notification_msg, set_notification_msg] = useState("");

  const globalContext = useContext(GlobalContext);

  function apptype() {return isInstalledApp() ? "native" : "pwa"}

  useEffect(() => {
    document.cookie = "appversion="+buildInfo.version+ ";path=/version;Domain=myflavoria.fi"
    document.cookie = "apptype="+apptype()+";path=/version;Domain=myflavoria.fi"

      //setupPushNotifications();
      isUpToDate().then((result) => {
        if(!result && isInstalledApp()) {
          set_notification_msg(t("New Version is available. Please update your My Flavoria® app."))
        }
      })
    
  },[])

  function initializeDarkPalette(isDark: boolean) {
    document.documentElement.classList.toggle("ion-palette-dark", isDark);
  }

  function useDarkTheme(): boolean {
    if (globalContext.themeDarknessPrefrence === "light") {
      return false;
    } else if (globalContext.themeDarknessPrefrence === "dark") {
      return true;
    } else {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
  }

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => initializeDarkPalette(useDarkTheme()));
  }, []);

  function loggedIn() {
    return (
      userContext.agreementAccepted &&
      userContext.wannaParticipate &&
      userContext.acceptedResearchType !== ResearchType.NONE
    );
  }

  initializeDarkPalette(useDarkTheme());

  if (!loggedIn()) {
    return (
      <IonApp>
        <IonContent className="ion-padding">
          <IonNav id="login-nav" root={() => <LoginPage />}></IonNav>
        </IonContent>
      </IonApp>
    );
  } else {
    return (
      <IonApp>
        <IonReactRouter>
          <FlavoriaHeaderBar
            subtitle={t(userContext.activePage)}
          ></FlavoriaHeaderBar>

          <IonMenu contentId="mainpage">
            <IonHeader>
              <IonToolbar color="secondary">
                <IonTitle>
                  <div>
                    <img
                      style={{ paddingTop: "5px" }}
                      height="40"
                      src={MyFlavoriaText}
                    />
                  </div>
                </IonTitle>
                <IonButtons slot="end">
                  <IonMenuToggle>
                    <IonButton
                      onClick={() => {
                        logout(userContext);
                      }}
                    >
                      <IonIcon aria-hidden="true" icon={logOutOutline} />
                    </IonButton>
                  </IonMenuToggle>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList style={{ paddingTop: "0px" }}>
                <IonItem color="secondary" lines="none">
                  <IonIcon
                    aria-hidden="true"
                    icon={personCircleOutline}
                    slot="start"
                  ></IonIcon>
                  <IonLabel>{userContext.email}</IonLabel>
                </IonItem>
                <IonMenuToggle>
                  <IonItem
                    button
                    onClick={() => {
                      set_static_content("restaurant-info");
                      set_modal_open(true);
                    }}
                  >
                    <IonIcon
                      aria-hidden="true"
                      icon={cafeOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>{t("Restaurant Info")}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
                <IonMenuToggle>
                  <IonItem
                    button
                    onClick={() => {
                      set_static_content("instructions");
                      set_modal_open(true);
                    }}
                  >
                    <IonIcon
                      aria-hidden="true"
                      icon={compassOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>{t("Guide")}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
                <IonMenuToggle>
                  <IonItem
                    button
                    onClick={() => {
                      set_static_content("terms-5");
                      set_modal_open(true);
                    }}
                  >
                    <IonIcon
                      aria-hidden="true"
                      icon={readerOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>{t("End User Agreement")}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
                <IonMenuToggle>
                  <IonItem button id="open-settings">
                    <IonIcon
                      aria-hidden="true"
                      icon={settingsOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>{t("Settings")}</IonLabel>
                  </IonItem>
                  <SettingsDialog trigger="open-settings" />
                </IonMenuToggle>
                <IonMenuToggle>
                  <IonItem
                    href={"https://redcap.utu.fi/surveys/?s=HLX4LFH4C4CF4TXR&__lang="+(getFlavoriaLang(i18next.language))+"&flavoriaid="+getSelfID()}
                    target="blank"
                  >
                    <IonIcon icon={happyOutline} slot="start"></IonIcon>
                    <IonLabel>{t("Give feedback")}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
                <IonMenuToggle>
                  <IonItem
                    button
                    onClick={() => {
                      logout(userContext);
                    }}
                  >
                    <IonIcon
                      aria-hidden="true"
                      icon={logOutOutline}
                      slot="start"
                    ></IonIcon>
                    <IonLabel>{t("Log Out")}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              </IonList>
            </IonContent>
          </IonMenu>

          <IonPage id="mainpage">
            <IonRouterOutlet>
              <Route exact path={`${rootPath}/home`}>
                <Home />
              </Route>
              <Route exact path={`${rootPath}/benefits`}>
                <Benefits />
              </Route>
              <Route exact path={`${rootPath}/lunchlist`}>
                <Lunchlist />
              </Route>
              <Route exact path={`${rootPath}/research`}>
                <Research />
              </Route>
              <Route exact path={`${rootPath}/meals`}>
                <Meals />
              </Route>
              <Route exact path={`${rootPath}/qr`}>
                <QR />
              </Route>
              <Route exact path={`${rootPath}/`}>
                <Redirect to={`${rootPath}/home`} />
              </Route>
              <Route exact path={`/`}>
                <Redirect to={`${rootPath}/home`} />
              </Route>
            </IonRouterOutlet>
          </IonPage>

          <IonTabBar slot="bottom" className="bottomMenuBar">
            <IonTabButton tab="tab1" href={`${rootPath}/home`}>
              <IonIcon aria-hidden="true" icon={myFlavoriaIcon} />
              <IonLabel className="tabButtonLabel">{t("My Flavoria")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab2" href={`${rootPath}/benefits`}>
              <IonIcon aria-hidden="true" icon={benefitsIcon} />
              <IonLabel className="tabButtonLabel">{t("Benefits")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab3" href={`${rootPath}/lunchlist`}>
              <IonIcon aria-hidden="true" icon={restaurantIcon} />
              <IonLabel className="tabButtonLabel">{t("Lunch List")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab4" href={`${rootPath}/research`}>
              <IonIcon aria-hidden="true" icon={researchIcon} />
              <IonLabel className="tabButtonLabel">{t("Research")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab5" href={`${rootPath}/meals`}>
              <IonIcon aria-hidden="true" icon={mealsIcon} />
              <IonLabel className="tabButtonLabel">{t("Data")}</IonLabel>
            </IonTabButton>
          </IonTabBar>

          <IonModal
            ref={modal}
            isOpen={modal_open}
            onDidDismiss={() => set_modal_open(false)}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => modal.current?.dismiss()}>
                    {t("Close")}
                  </IonButton>
                </IonButtons>
                <IonTitle>{t(static_content)}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <StaticPage pageName={static_content}></StaticPage>
          </IonModal>
          <SoftwareNotification onDidDismiss={() => {set_notification_msg("")}} showMealExtraPopup={notification_msg != ""} message={notification_msg}/>
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default App;
