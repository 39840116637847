import React from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { rootPath } from "../Utils";

import QRIcon from "../assets/icon/svgicons/flavoriaQR.svg";

interface Props {
  subtitle: string;
}
const FlavoriaHeaderBar: React.FC<Props> = (props: Props) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonTitle>
            {props.subtitle}
        </IonTitle>
        <IonButtons slot="end">
          <IonButton routerLink={`${rootPath}/qr`}>
            <IonIcon
              color={location.pathname == `${rootPath}/qr` ? "primary" : ""}
              size="large"
              icon={QRIcon}
            />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default FlavoriaHeaderBar;
