import isCapacitor from "./IsCapacitor";

export function getPWADisplayMode() {
    if (document.referrer.startsWith('android-app://'))
      return 'twa';
    if (window.matchMedia('(display-mode: browser)').matches)
      return 'browser';
    if (window.matchMedia('(display-mode: standalone)').matches)
      return 'standalone';
    if (window.matchMedia('(display-mode: minimal-ui)').matches)
      return 'minimal-ui';
    if (window.matchMedia('(display-mode: fullscreen)').matches)
      return 'fullscreen';
    if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
      return 'window-controls-overlay';
  
    return 'unknown';
  }

  /* Is using installed App (from app store, play store or PWA) */
  export function isUsingInstalledApp() {
    if (isCapacitor)
      return true;
    if (document.referrer.startsWith('android-app://'))
      return true;
    if (window.matchMedia('(display-mode: browser)').matches)
      return false;
    if (window.matchMedia('(display-mode: standalone)').matches)
      return true;
    if (window.matchMedia('(display-mode: minimal-ui)').matches)
      return true;
    if (window.matchMedia('(display-mode: fullscreen)').matches)
      return false;
    if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
      return true;
  
    return false;
  }