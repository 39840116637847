import BackgroundService from "./BackgroundService";
import NewMealCheck from "./NewMealCheck";

const _serviceDict: Record<string, BackgroundService> = {};

function registerService(serviceName: string, service: BackgroundService) {
  _serviceDict[serviceName] = service;
}

/** BACKGROUND SERVICES ARE REGISTERED HERE: **/

registerService("newMealCheck", new NewMealCheck());

/** END OF BACKGROUND SERVICE REGISTERATION **/

function requestBackgroundService(
  serviceName: string
): BackgroundService | null {
  if (serviceName in _serviceDict) return _serviceDict[serviceName];

  return null;
}

export default requestBackgroundService;
