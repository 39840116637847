import React, { useContext, useState } from "react";
import {
  IonBadge,
  IonCard,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import TemplateCard from "../components/TemplateCard";
import GlobalContext from "../GlobalContext";
import { logout_sessionexpired } from "../logout";
import BackendFactory from "../API/BackendFactory";
import { ResearchListObject } from "../API/lib/JsonUtils";
import { openOutline } from "ionicons/icons";
import Debouncer from "../components/DebouncerUtility";
import FlavoriaPage from "../components/FlavoriaPage";
import ErrorCard from "../components/ErrorCard";
import i18n from "../i18n";
import getFlavoriaLang from "../utils/flavoriaLang";

const debouncer = new Debouncer();
const debouncer2 = new Debouncer();

const Research: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [earlierResearches, setEarlierResearches] = useState("");
  const [currentResearches, setCurrentResearches] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const globalContext = useContext(GlobalContext);

  debouncer.set_debouncer(() => {
    const BE = BackendFactory.getBackend();
    Promise.all([BE.getEarlierResearches(), BE.getCurrentResearches()])
      .then((results) => {
        setEarlierResearches(results[0]);
        setCurrentResearches(results[1]);
        setLoading(false);
      })
      .catch((reason) => {
        if (reason == "Expired Token") {
          logout_sessionexpired(globalContext, t);
        } else {
          if(typeof reason == "string")
            setErrorCode(reason)
          else
            setErrorCode("UnexpectedError");

          setLoading(false);
        }
      });
  });

  function renderCards() {
    if (loading) {
      return (
        <div>
          <TemplateCard />
          <TemplateCard />
          <TemplateCard />
        </div>
      );
    } else if (errorCode != ""){
      return (<ErrorCard errorCode={errorCode}/>)
    } else {
      if (
        (earlierResearches === "[]" || earlierResearches === "") &&
        (currentResearches === "[]" || currentResearches === "")
      ) {
        return (
          <IonCard>
            <IonCardHeader>
              <IonTitle>{t("No researches currently.")}</IonTitle>
            </IonCardHeader>
          </IonCard>
        );
      } else {
        const curr = new ResearchListObject(currentResearches);
        const past = new ResearchListObject(earlierResearches);
        const BE = BackendFactory.getBackend();
        debouncer2.set_debouncer(() => {
          curr
            .getResearchItems()
            .map((research) => BE.markResearchAsSeen(research.id));
        });
        debouncer2.call();

        const list_curr: Array<React.ReactElement> = curr
          .getResearchItems()
          .map((research): React.ReactElement => {
            return (
              <span key={"new" + research.key} style={{"display" : getFlavoriaLang(i18n.language) == research.lang ? "inline" : "none"}}>
                <IonItem
                  target="_blank"
                  href={research.link}
                  detail={true}
                  detailIcon={openOutline}
                >
                  <IonLabel>
                    <h3>{research.title}</h3>
                    <p>{research.desc}</p>
                  </IonLabel>
                  <IonBadge slot="end">{t("New!")}</IonBadge>
                </IonItem>
              </span>
            );
          });
        const list_past: Array<React.ReactElement> = past
          .getResearchItems()
          .map((research): React.ReactElement => {
            return (
              <span key={"old" + research.key} style={{"display" : getFlavoriaLang(i18n.language) == research.lang ? "inline" : "none"}}>
                <IonItem
                  target="_blank"
                  href={research.link}
                  detail={true}
                  detailIcon={openOutline}
                >
                  <IonLabel>
                    <h3>{research.title}</h3>
                    <p>{research.desc}</p>
                  </IonLabel>
                </IonItem>
              </span>
            );
          });

        return <IonList>{list_curr.concat(list_past)}</IonList>;
      }
    }
  }

  if (loading) {
    debouncer.call();
  }

  return (
    <FlavoriaPage pageID={"ACTIVE_PAGE_RESEARCH"}>{renderCards()}</FlavoriaPage>
  );
};

export default Research;
