import { IonBadge } from "@ionic/react";
import React, { createRef } from "react";
import reset_animation from "../utils/reflow";

interface CountBadgeProps {
  qty: number;
  slot?: string;
}

export default class CountBadge extends React.Component<CountBadgeProps> {
  inputRef = createRef<HTMLIonBadgeElement>();
  oldQty = 0;

  constructor(props: CountBadgeProps) {
    super(props);
    this.oldQty = this.props.qty;
  }
  componentDidUpdate(): void {
    if (this.oldQty == this.props.qty)
      return
    const self = this.inputRef.current; 
    if (self) { reset_animation(self); }
    this.oldQty = this.props.qty;
  }

  render() {
    if (this.props.slot)
      return (
          <IonBadge slot={this.props.slot} ref={this.inputRef} class={"badgeBounce"} color={"dark"}>{this.props.qty + " x"}</IonBadge>
      );
      else
      return (
        <IonBadge ref={this.inputRef} class={"badgeBounce"} color={"dark"}>{this.props.qty + " x"}</IonBadge>
      );
  }
}
